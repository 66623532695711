import { DisplayData, TenantOrderLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiJob from '~/tenants/mpi/model/MpiJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import MpiVideoConfig, { MpiVideoType } from './MpiVideoConfig';
import Big from 'big.js';

export const MPI_VIDEO_WALK_THROUGH_AERIAL = 325;
export const MPI_VIDEO_WALK_TRHOUGH = 225;
export const MPI_VIDEO_AERIAL = 175;
export const MPI_VIDEO_AGENT_ON_CAMERA = 250;

export default class MpiVideoJob extends MpiJob<typeof MpiVideoConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiVideoConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: MpiVideoType.WALK_THROUGH_AERIAL,
    };
  }

  get deliverable() {
    return DeliverableType.VIDEO;
  }

  get media() {
    return { persona: PersonaType.PROVIDER };
  }

  get configurable(): boolean {
    return true;
  }

  onsite(): number {
    // TODO: Joel: Need to confirm onsite times for this job
    switch (this.metadata.type) {
      case MpiVideoType.WALK_THROUGH_AERIAL:
        return 90;
      case MpiVideoType.WALK_THROUGH:
        return 60;
      case MpiVideoType.AERIAL:
        return 30;
    }
  }

  info(): DisplayData[] {
    const info = super.info();

    if (this.metadata.type) {
      info.push({
        name: 'Type',
        value: (() => {
          switch (this.metadata.type) {
            case MpiVideoType.WALK_THROUGH_AERIAL:
              return 'Walk Through + Aerial';
            case MpiVideoType.WALK_THROUGH:
              return 'Walk Through';
            case MpiVideoType.AERIAL:
              return 'Aerial';
          }
        })(),
        provider: true,
        schedule: true,
      });
    }

    return info;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    lines.push({
      amount: (() => {
        switch (this.metadata.type) {
          case MpiVideoType.WALK_THROUGH_AERIAL:
            return new Big(MPI_VIDEO_WALK_THROUGH_AERIAL);
          case MpiVideoType.WALK_THROUGH:
            return new Big(MPI_VIDEO_WALK_TRHOUGH);
          case MpiVideoType.AERIAL:
            return new Big(MPI_VIDEO_AERIAL);
        }
      })(),
      description: 'Listing Video',
      id: 'video',
    });

    if (this.metadata.agent_on_camera) {
      lines.push({
        id: 'agent',
        description: 'Agent On Camera',
        amount: new Big(MPI_VIDEO_AGENT_ON_CAMERA),
      });
    }

    return lines;
  }

  get performable() {
    return MpiVideoConfig;
  }
}
