import classNames from 'classnames';
import { useId } from 'react';

export interface InputRadioCardsOption {
  title: string;
  description?: string;
  disabled?: boolean;
  value: string;
}

export interface InputRadioCardsProps {
  cols?: number;
  clearable?: boolean
  disabled?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: string | null) => void;
  onFocus?: () => void;
  options: InputRadioCardsOption[];
  value?: string | null;
}

export default function InputRadioCards(props: InputRadioCardsProps) {
  const { cols = 3, clearable, disabled, name, onBlur, onChange, onFocus, options, value } = props;

  const optionsId = useId();

  return (
    <div className={`grid grid-cols-1 md:grid-cols-${cols} gap-3`}>
      {options.map((option) => {
        const selected = value === option.value;

        return (
          <button
            key={`${optionsId}-${option.value}`}
            className={classNames('flex flex-col px-6 py-3 text-left border border-gray-300 rounded-theme outline-none transition ease-in-out duration-200',
              {
                'hover:border-gray-400': !disabled && !selected,
                'border-gray-900 ring-1 ring-black': selected,
                'bg-gray-100': disabled,
              }
            )}
            onClick={() => onChange?.(selected && clearable ? null: option.value)}
            disabled={disabled || option.disabled}
            onFocus={onFocus}
            onBlur={onBlur}
            type="button"
          >
            <span className={classNames('font-bold', { 'text-gray-400': disabled, 'text-gray-800': !disabled })}>
              {option.title}
            </span>
            {option.description && (
              <span className={classNames('text-sm', { 'text-gray-400': disabled, 'text-gray-700': !disabled })}>
                {option.description}
              </span>
            )}
          </button>
        );
      })}

      {name && value && (
        <input name={name} type="hidden" value={value} />
      )}
    </div>
  );
}
