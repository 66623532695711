import FormGroup from '~/components/form/FormGroup';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';

export default function BreSocialSubmitForm() {
  return (
    <FormGroup>
      <label className="flex items-center gap-2" htmlFor="self_edited">
        <ZodFieldCheckbox id="self_edited" name="self_edited" />
        Self-edited video
      </label>
    </FormGroup>
  );
}
