import { registerTenant, TenantId } from '~/tenants/common/registry';
import { TimeZone } from '~/lib/enum';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiPhotosJob from '~/tenants/mpi/performable/photos/MpiPhotosJob';
import MpiPhotosForm from '~/tenants/mpi/performable/photos/MpiPhotosForm';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import MpiProvider from '~/tenants/mpi/model/MpiProvider';
import { MPI_PROVIDER_DATA } from '~/tenants/mpi/data';
import MpiCustomer, { MpiCustomerSchema } from '~/tenants/mpi/model/MpiCustomer';
import MpiOrderWizardType from '~/tenants/mpi/forms/MpiOrderWizardType';
import { MpiOrder, MpiOrderSchema } from '~/tenants/mpi/model/MpiOrder';
import MpiOrderForm from '~/tenants/mpi/forms/MpiOrderForm';
import MpiVideoConfig from './performable/video/MpiVideoConfig';
import MpiVideoJob from './performable/video/MpiVideoJob';
import MpiVideoForm from './performable/video/MpiVideoFrom';
import MpiFloorplanConfig from './performable/floorplan/MpiFloorplanConfig';
import MpiFloorplanJob from './performable/floorplan/MpiFloorplanJob';
import MpiFloorplanForm from './performable/floorplan/MpiFloorplanForm';
import MpiIguideConfig from './performable/iguide/MpiIguideConfig';
import MpiIguideJob from './performable/iguide/MpiIguideJob';
import MpiIGuideForm from './performable/iguide/MpiIguideForm';
import MpiVirtualStagingConfig from './performable/staging/MpiVirtualStagingConfig';
import MpiVirtualStagingJob from './performable/staging/MpiVirtualStagingJob';
import MpiVirtualStagingForm from './performable/staging/MpiVirtualStagingForm';
import MpiMatterportConfig from './performable/matterport/MpiMatterportConfig';
import MpiMatterportJob from './performable/matterport/MpiMatterportJob';
import MpiMatterportForm from './performable/matterport/MpiMatterportForm';
import MpiAerialConfig from './performable/aerial/MpiAerialConfig';
import MpiAerialJob from './performable/aerial/MpiAerialJob';
import MpiAerialForm from './performable/aerial/MpiAerialForm';
import MpiBrochuresConfig from './performable/brochures/MpiBrochuresConfig';
import MpiBrochuresJob from './performable/brochures/MpiBrochuresJob';
import MpiBrochuresForm from './performable/brochures/MpiBrochuresForm';

export default function registerMpi() {
  registerTenant(TenantId.MPI, MpiOrderSchema, {
    name: 'Maryland Photography Inc.',
    domain: 'mpi.photology.co',
    phone: '+14106958100',
    logoRaster: 'https://optimize.photology.co/tenant/mpi/logo.png',
    logoVector: 'https://optimize.photology.co/tenant/mpi/logo.svg',
    background: '#F05722',
    legal: 'Maryland Photography Inc.',
    email: 'mpi@photology.co',
    emailEnable: true,
    userback: true,
    providerClass: MpiProvider,
    providers: MPI_PROVIDER_DATA,
    scheduleStartTime: '06:00',
    scheduleEndTime: '20:00',
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    customerClass: MpiCustomer,
    locations: [
      {
        slug: 'clb',
        name: 'Columbia',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: '9570 Berger Rd Suite D',
          city: 'Columbia',
          state: 'MD',
          zip: '21046',
          lat: 39.17338,
          long: -76.84341,
        },
      },
    ],
    orderClass: MpiOrder,
    packages: [],
    customerSchema: MpiCustomerSchema,
    forms: {
      orderConfigure: MpiOrderForm,
      wizardType: MpiOrderWizardType,
      provider: EmptyForm,
    },
    performables: {
      [MpiPhotosConfig.id]: {
        config: MpiPhotosConfig,
        job: MpiPhotosJob,
        form: MpiPhotosForm,
      },
      [MpiVideoConfig.id]: {
        config: MpiVideoConfig,
        job: MpiVideoJob,
        form: MpiVideoForm,
      },
      [MpiFloorplanConfig.id]: {
        config: MpiFloorplanConfig,
        job: MpiFloorplanJob,
        form: MpiFloorplanForm,
      },
      [MpiIguideConfig.id]: {
        config: MpiIguideConfig,
        job: MpiIguideJob,
        form: MpiIGuideForm,
      },
      [MpiMatterportConfig.id]: {
        config: MpiMatterportConfig,
        job: MpiMatterportJob,
        form: MpiMatterportForm,
      },
      [MpiVirtualStagingConfig.id]: {
        config: MpiVirtualStagingConfig,
        job: MpiVirtualStagingJob,
        form: MpiVirtualStagingForm,
      },
      [MpiAerialConfig.id]: {
        config: MpiAerialConfig,
        job: MpiAerialJob,
        form: MpiAerialForm,
      },
      [MpiBrochuresConfig.id]: {
        config: MpiBrochuresConfig,
        job: MpiBrochuresJob,
        form: MpiBrochuresForm,
      },
    },
  });
}
