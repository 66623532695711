import { TenantPackageConfig } from '~/tenants/common/registry';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import { BreOrderSchema, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';
import { FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import dedent from 'dedent';

const BreSocialPackageConfig: TenantPackageConfig = {
  id: 'social',
  name: 'Social Media',
  performables: [
    BrePhotosConfig,
    BreWebsiteConfig,
    BreFloorplanConfig,
    BreBoostConfig,
    BreSocialConfig,
    BreDuskConfig,
  ],
  eligible: (context) => {
    const metadata = BreOrderSchema[FIRST_VERSION_TIMESTAMP].parse(context.metadata);

    return metadata.type === BreOrderType.RESIDENTIAL && metadata.sqft <= 5000;
  },
  description: dedent`
    Includes our ground photography, a 2D Floor Plan, the single property website \
    page, a social media boost, a 1 Minute Social Media Video, and 1 complementary \
    Day-to-Dusk Photo Edit.
  `,
};

export default BreSocialPackageConfig;
