import BreJob from '~/tenants/bre/model/BreJob';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { DeliverableType } from '~common/model/Deliverable';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreBoostJob from '~/tenants/bre/performable/boost/BreBoostJob';
import { match } from 'ts-pattern';

const BaseRates = {
  BASE_VIDEO: '300',
  AERIAL_PHOTOS: '100',
  AERIAL_VIDEO: '115',
  INTRO: '50',
  NARRATION:'100',
  TWILIGHT: '300',
  VOICEOVER: '125',
};

export const BreSocialRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BreSocialJob extends BreJob<typeof BreSocialConfig> {
  get performable() {
    return BreSocialConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get submission(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.SOCIAL;
  }

  get editing(): boolean {
    return !this.metadata.self_edited;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreSocialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  // TODO: Waiting onsite times from Chris
  onsite(): number {
    let onsite = 0;

    if (this.metadata.intro || this.metadata.narration) {
      onsite += 60;
    } else {
      onsite += 45;
    }

    if (this.metadata.voiceover) {
      onsite += 30;
    }

    return onsite;
  }

  revenueLines(): TenantJobLine[] {
    const {
      AERIAL_PHOTOS,
      AERIAL_VIDEO,
      BASE_VIDEO,
      INTRO,
      NARRATION,
      TWILIGHT,
      VOICEOVER,
    } = BreSocialRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'social_video',
      description: 'Basic: Vertical Ground Video Footage',
      amount: new Big(BASE_VIDEO),
      discountable: true,
    });

    if (this.matchSocialPackage) {
      lines.push({
        id: 'social_package',
        description: 'Social Media Package Video Discount',
        amount: new Big(BASE_VIDEO).times(-0.1),
        discount: true,
      });
    }

    if (this.metadata.aerial_photos) {
      lines.push({
        id: 'aerial_photos',
        description: '5 Aerial Photos',
        amount: new Big(AERIAL_PHOTOS).times(this.extraFee),
      });
    }

    if (this.metadata.aerial_video) {
      lines.push({
        id: 'aerial_video',
        description: 'Aerial Video',
        amount: new Big(AERIAL_VIDEO),
      });
    }

    if (this.metadata.aerial_photos && this.metadata.aerial_video) {
      lines.push({
        id: 'aerial_combo',
        description: 'Aerial Combo Discount',
        discount: true,
        amount: new Big('-15'),
      });
    }

    if (this.metadata.intro) {
      lines.push({
        id: 'intro',
        description: 'Agent Intro',
        amount: new Big(INTRO),
      });
    }

    if (this.metadata.narration) {
      lines.push({
        id: 'narration',
        description: 'Agent Narration',
        amount: new Big(NARRATION),
      });
    }

    if (this.metadata.intro && this.metadata.narration) {
      lines.push({
        id: 'intro_narration_combo',
        description: 'Agent Intro & Narration Discount',
        discount: true,
        amount: new Big('-50'),
      });
    }

    if (this.metadata.voiceover) {
      lines.push({
        id: 'voiceover',
        description: 'Kwasi Voice Over',
        amount: new Big(VOICEOVER),
      });
    }

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Upgrade',
        amount: new Big(TWILIGHT),
      });
    }

    if (this.metadata.boost) {
      const boostJob = new BreBoostJob(this.order, undefined, {
        version: this.metadata.version,
        ...this.metadata.boost,
      });

      lines.push(...boostJob.revenueLines());
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const {
      AERIAL_PHOTOS,
      AERIAL_VIDEO,
      BASE_VIDEO,
      INTRO,
      NARRATION,
    } = BreSocialRate[this.order.metadata.type];
    const videoSplit = this.metadata.self_edited ? 0.5 : 0.7;
    const lines: TenantJobLine[] = [];

    lines.push({
      id: 'social_video',
      description: 'Basic: Vertical Ground Video Footage',
      amount: new Big(BASE_VIDEO).times(videoSplit).times(this.matchSocialPackage ? 0.9 : 1),
    });

    if (this.metadata.aerial_photos) {
      lines.push({
        id: 'aerial_photos',
        description: '5 Aerial Photos',
        amount: new Big(AERIAL_PHOTOS).times(0.65),
      });
    }

    if (this.metadata.aerial_video) {
      lines.push({
        id: 'aerial_video',
        description: 'Aerial Video',
        amount: new Big(AERIAL_VIDEO).times(videoSplit),
      });
    }

    if (this.metadata.intro && this.metadata.narration) {
      lines.push({
        id: 'intro_narration_combo',
        description: 'Agent Intro & Narration Combo',
        amount: new Big(INTRO).plus(NARRATION).minus('50').times(videoSplit),
      });
    } else {
      if (this.metadata.intro) {
        lines.push({
          id: 'intro',
          description: 'Agent Intro',
          amount: new Big(INTRO).times(videoSplit),
        });
      }
  
      if (this.metadata.narration) {
        lines.push({
          id: 'narration',
          description: 'Agent Narration',
          amount: new Big(NARRATION).times(videoSplit),
        });
      }
    }

    if (this.metadata.voiceover) {
      lines.push({
        id: 'narration',
        description: 'Kwasi Voice Over',
        amount: new Big('75'),
      });
    }

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Upgrade',
        amount: new Big('195'),
      });
    }

    return lines;
  }
}
