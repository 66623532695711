import { DisplayData, TenantOrderLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiJob from '../../model/MpiJob';
import MpiAerialConfig from './MpiAerialConfig';
import Big from 'big.js';

export default class MpiAerialJob extends MpiJob<typeof MpiAerialConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiAerialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite() {
    return 30;
  }

  get performable() {
    return MpiAerialConfig;
  }

  get configurable() {
    return true;
  }

  info(): DisplayData[] {
    const info = super.info();

    return info;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      id: 'aerial',
      description: 'Aerial Photography',
      amount: new Big(175),
    });

    return lines;
  }
}
