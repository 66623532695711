import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BrePortraitsRate } from '~/tenants/bre/performable/portraits/BrePortraitsJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const TYPE_OPTS: InputSelectOption[] = [
  { label: 'In Studio Photo Shoot', value: 'studio' },
  { label: 'Lifestyle Photo Session', value: 'onsite' },
];

export default function BrePortraitsForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { EXTRA_EDIT, EXTRA_POSE } = BrePortraitsRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type">
        <ZodFieldSelect name="type" options={TYPE_OPTS} />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="extra_edit"
          title="Addtitional Edited Photo"
          description="Add an extra edited photo"
          image="" // ? what image should we use here
          persona={persona}
          cost={EXTRA_EDIT}
        />
        <TenantPackageAddOnCheckbox
          name="extra_pose"
          title="Additional Lifestyle Photo"
          description="Add an extra lifestyle photo"
          image="" // ? what image should we use here
          persona={persona}
          cost={EXTRA_POSE}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
