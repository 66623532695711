import Big from 'big.js';
import { DateTime } from 'luxon';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import BreBoostConfig, { REACH_VALUES, Reach } from '~/tenants/bre/performable/boost/BreBoostConfig';

export default class BreBoostJob extends BreJob<typeof BreBoostConfig> {
  get performable() {
    return BreBoostConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get reachRevenue(): Big {
    return BreBoostJob.reachRevenue(this.metadata.reach);
  }

  static reachRevenue(reach: Reach): Big {
    const index = REACH_VALUES.indexOf(reach);

    return new Big('100').times(index).add(200);
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreBoostConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      reach: '3,000',
      start_at: DateTime.now().toSQLDate(),
    };
  }

  revenueLines(): TenantJobLine[] {
    const lines: TenantJobLine[] = [];

    if (this.metadata.reach) {
      lines.push({
        id: 'boost_reach',
        description: `${this.metadata.reach} Potencial Reach`,
        amount: this.reachRevenue,
        discountable: true,
      });

      if (this.matchSocialPackage) {
        lines.push({
          id: 'boost_reach_package',
          description: 'Social Media Package Boost Discount',
          amount: this.reachRevenue.times(-0.1),
          discount: true,
        });
      }
    }

    return lines;
  }
}
