import BreJob from '~/tenants/bre/model/BreJob';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { DeliverableType } from '~common/model/Deliverable';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';

const BaseRates = {
  AERIAL_PHOTOS: '100',
  INTRO: '175',
  NARRATION: '250',
  RUSH: '200',
  VOICEOVER: '250',
  TWILIGHT: '400',
};

export const BreCinematicRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BreCinematicJob extends BreJob<typeof BreCinematicConfig> {
  get performable() {
    return BreCinematicConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get submission(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.VIDEO;
  }

  get editing() {
    return !this.metadata.self_edited;
  }

  get videoRevenue(): Big {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const isCommercial = this.order.metadata.type === BreOrderType.COMMERCIAL;

      if (this.metadata.aerial_upgrade) {
        return isCommercial && this.metadata.duration
          ? new Big('625').times(this.metadata.duration).add(-189)
          : new Big('0.21').times(this.order.metadata.sqft).add(434)
      }

      return isCommercial && this.metadata.duration
        ? new Big('625').times(this.metadata.duration).add(-539)
        : new Big('0.20').times(this.order.metadata.sqft).add(199)
    }

    return new Big('0');
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreCinematicConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  // TODO: Waiting for times from Chris
  onsite(): number {
    let onsite = 0;

    return onsite;
  }

  revenueLines(): TenantJobLine[] {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const {
        AERIAL_PHOTOS,
        INTRO,
        NARRATION,
        RUSH,
        VOICEOVER,
        TWILIGHT,
      } = BreCinematicRate[this.order.metadata.type];
      const lines: TenantJobLine[] = [];

      if (this.order.metadata.sqft > 0) {
        lines.push({
          id: 'video',
          description: `Cinematic Film${this.metadata.aerial_upgrade ? ' (with aerial)' : ''}`,
          amount: this.videoRevenue,
        });
      }

      if (this.order.metadata.rush) {
        lines.push({
          id: 'rush',
          description: 'Rush Fee',
          amount: new Big(RUSH),
        });
      }

      if (this.metadata.intro) {
        lines.push({
          id: 'intro',
          description: 'Agent Intro',
          amount: new Big(INTRO),
        });
      }
  
      if (this.metadata.narration) {
        lines.push({
          id: 'narration',
          description: 'Agent Narration',
          amount: new Big(NARRATION),
        });
      }
  
      if (this.metadata.voiceover) {

        lines.push({
          id: 'voiceover',
          description: 'Kwasi Voice Over (3 mins)',
          amount: new Big(VOICEOVER),
        });
      }
  
      if (this.metadata.twilight) {
        lines.push({
          id: 'twilight',
          description: 'Twilight Transitions',
          amount: new Big(TWILIGHT).times(this.extraFee),
        });
      }
  
      if (this.metadata.aerial_photos) {
        lines.push({
          id: 'aerial_photos',
          description: '5 Aerial Photos',
          amount: new Big(AERIAL_PHOTOS).times(this.extraFee),
        });
      }
  
      return lines;
    }

    return [];
  }

  expenseLines(): TenantJobLine[] {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const lines: TenantJobLine[] = [];

      if (this.order.metadata.sqft > 0) {
        lines.push({
          id: 'video',
          description: `Cinematic Film${this.metadata.aerial_upgrade ? ' (with aerial)' : ''}`,
          amount: this.videoRevenue.times(this.metadata.self_edited ? 0.5 : 0.7),
        });
      }

      if (this.metadata.intro) {
        lines.push({
          id: 'intro',
          description: 'Agent Intro',
          amount: new Big('88'),
        });
      }

      if (this.metadata.narration) {
        lines.push({
          id: 'narration',
          description: 'Agent Narration',
          amount: new Big('125'),
        });
      }

      if (this.metadata.voiceover) {
        lines.push({
          id: 'voiceover',
          description: 'Kwasi Voice Over (3 mins)',
          amount: new Big('125'),
        });
      }

      if (this.metadata.twilight) {
        lines.push({
          id: 'twilight',
          description: 'Twilight Transitions',
          amount: new Big('200'),
        });
      }
  
      if (this.metadata.aerial_photos) {
        lines.push({
          id: 'aerial_photos',
          description: '5 Aerial Photos',
          amount: new Big('100').times(0.65),
        });
      }
  
      return lines;
    }

    return [];
  }
}
