import { registerTenant, TenantId } from '~/tenants/common/registry';
import { BreOrderSchema } from '~/tenants/bre/model/BreOrderContext';
import { BreOrder } from '~/tenants/bre/model/BreOrder';
import { TimeZone } from '~/lib/enum';
import BreProvider from '~/tenants/bre/model/BreProvider';
import BreOrderForm from '~/tenants/bre/forms/BreOrderForm';
import z from 'zod';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import EmptyForm from '~/tenants/common/forms/EmptyForm';
import BreCustomer from '~/tenants/bre/model/BreCustomer';
import { BRE_PROVIDER_DATA } from '~/tenants/bre/data';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';
import BreAerialForm from '~/tenants/bre/performable/aerial/BreAerialForm';
import BreAerialSubmitForm from '~/tenants/bre/performable/aerial/BreAerialSubmitForm';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreBoostJob from '~/tenants/bre/performable/boost/BreBoostJob';
import BreBoostForm from '~/tenants/bre/performable/boost/BreBoostForm';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreCinematicJob from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import BreCinematicForm from '~/tenants/bre/performable/cinematic/BreCinematicForm';
import BreCinematicSubmitForm from '~/tenants/bre/performable/cinematic/BreCinematicSubmitForm';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';
import BreDuskJob from '~/tenants/bre/performable/dusk/BreDuskJob';
import BreDuskForm from '~/tenants/bre/performable/dusk/BreDuskForm';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreFloorplanJob from '~/tenants/bre/performable/floorplan/BreFloorplanJob';
import BreFloorplanForm from '~/tenants/bre/performable/floorplan/BreFloorplanForm';
import BreMatterportConfig from '~/tenants/bre/performable/matterport/BreMatterportConfig';
import BreMatterportJob from '~/tenants/bre/performable/matterport/BreMatterportJob';
import BreMatterportForm from '~/tenants/bre/performable/matterport/BreMatterportForm';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';
import BrePhotosForm from '~/tenants/bre/performable/photos/BrePhotosForm';
import BrePortraitsConfig from '~/tenants/bre/performable/portraits/BrePortraitsConfig';
import BrePortraitsJob from '~/tenants/bre/performable/portraits/BrePortraitsJob';
import BrePortraitsForm from '~/tenants/bre/performable/portraits/BrePortraitsForm';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreSocialJob from '~/tenants/bre/performable/social/BreSocialJob';
import BreSocialForm from '~/tenants/bre/performable/social/BreSocialForm';
import BreSocialSubmitForm from '~/tenants/bre/performable/social/BreSocialSubmitForm';
import BreStagingConfig from '~/tenants/bre/performable/staging/BreStagingConfig';
import BreStagingJob from '~/tenants/bre/performable/staging/BreStagingJob';
import BreStagingForm from '~/tenants/bre/performable/staging/BreStagingForm';
import BreTwilightConfig from '~/tenants/bre/performable/twilight/BreTwilightConfig';
import BreTwilightJob from '~/tenants/bre/performable/twilight/BreTwilightJob';
import BreTwilightForm from '~/tenants/bre/performable/twilight/BreTwilightFrom';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import BreWebsiteJob from '~/tenants/bre/performable/website/BreWebsiteJob';
import BreWebsiteForm from '~/tenants/bre/performable/website/BreWebsiteForm';
import BreSocialPackageConfig from '~/tenants/bre/package/social/BreSocialPackageConfig';
import BreSocialPackageForm from '~/tenants/bre/package/social/BreSocialPackageForm';
import BrePhotoPackageConfig from '~/tenants/bre/package/photo/BrePhotoPackageConfig';
import BrePhotoPackageForm from '~/tenants/bre/package/photo/BrePhotoPackageForm';
import BreOrderWizardType from '~/tenants/bre/forms/BreOrderWizardType';
import BreCinematicsPackageConfig from '~/tenants/bre/package/cinematics/BreCinematicsPackageConfig';
import BreCinematicPackageForm from '~/tenants/bre/package/cinematics/BreCinematicsPackageForm';

export default function registerBre() {
  registerTenant(TenantId.BEYOND_RE_MARKETING, BreOrderSchema, {
    name: 'Beyond RE Marketing',
    domain: 'bre.photology.co',
    logoRaster: 'https://optimize.photology.co/tenant/bre/logo.png',
    legal: 'TBD',
    phone: '+15104409153',
    email: 'bre@photology.co',
    emailEnable: true,
    background: '#402040',
    scheduleStartTime: '08:00',
    scheduleEndTime: '20:00',
    resend: 're_5aDzRJPq_X2LfmZKyb6nMEG989ourG2vE',
    customerClass: BreCustomer,
    locations: [
      {
        slug: 'sjc',
        name: 'San Jose',
        timezone: TimeZone.US_CENTRAL,
        address: {
          line1: '15495 Los Gatos Boulevard',
          line2: null,
          city: 'Los Gatos',
          state: 'CA',
          zip: '95032',
          long: -121.9607408,
          lat: 37.2442195,
        },
      },
    ],
    orderClass: BreOrder,
    packages: [
      {
        config: BrePhotoPackageConfig,
        form: BrePhotoPackageForm,
      },
      {
        config: BreSocialPackageConfig,
        form: BreSocialPackageForm,
      },
      {
        config: BreCinematicsPackageConfig,
        form: BreCinematicPackageForm,
      },
    ],
    providerClass: BreProvider,
    providers: BRE_PROVIDER_DATA,
    customerSchema: {
      [FIRST_VERSION_TIMESTAMP]: z.object({ version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP) }),
    },
    forms: {
      orderConfigure: BreOrderForm,
      wizardType: BreOrderWizardType,
      provider: EmptyForm,
    },
    performables: {
      [BreAerialConfig.id]: {
        config: BreAerialConfig,
        job: BreAerialJob,
        form: BreAerialForm,
        submit: BreAerialSubmitForm,
      },
      [BreBoostConfig.id]: {
        config: BreBoostConfig,
        job: BreBoostJob,
        form: BreBoostForm,
      },
      [BreCinematicConfig.id]: {
        config: BreCinematicConfig,
        job: BreCinematicJob,
        form: BreCinematicForm,
        submit: BreCinematicSubmitForm,
      },
      [BreDuskConfig.id]: {
        config: BreDuskConfig,
        job: BreDuskJob,
        form: BreDuskForm,
      },
      [BreFloorplanConfig.id]: {
        config: BreFloorplanConfig,
        job: BreFloorplanJob,
        form: BreFloorplanForm,
      },
      [BreMatterportConfig.id]: {
        config: BreMatterportConfig,
        job: BreMatterportJob,
        form: BreMatterportForm,
      },
      [BrePhotosConfig.id]: {
        config: BrePhotosConfig,
        job: BrePhotosJob,
        form: BrePhotosForm,
      },
      [BrePortraitsConfig.id]: {
        config: BrePortraitsConfig,
        job: BrePortraitsJob,
        form: BrePortraitsForm,
      },
      [BreSocialConfig.id]: {
        config: BreSocialConfig,
        job: BreSocialJob,
        form: BreSocialForm,
        submit: BreSocialSubmitForm,
      },
      [BreStagingConfig.id]: {
        config: BreStagingConfig,
        job: BreStagingJob,
        form: BreStagingForm,
      },
      [BreTwilightConfig.id]: {
        config: BreTwilightConfig,
        job: BreTwilightJob,
        form: BreTwilightForm,
      },
      [BreWebsiteConfig.id]: {
        config: BreWebsiteConfig,
        job: BreWebsiteJob,
        form: BreWebsiteForm,
      },
    },
  });
}
