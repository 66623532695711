import BreJob from '~/tenants/bre/model/BreJob';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { MicrositeFileType } from '~microsite/lib/types';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { DeliverableType } from '~common/model/Deliverable';
import { match } from 'ts-pattern';

const BaseRates = {
  COMBO: '500',
  FPV_BOTH: '600',
  FPV_OUTSIDE: '299',
  PHOTOS_5: '150',
  PHOTOS_10: '299',
  POI: '20',
  RUSH: '150',
  TWILIGHT: '299',
  VIDEO: '299',
};

export const BreAerialRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    COMBO: '650',
    PHOTOS_5: '200',
    PHOTOS_10: '400',
    POI: '160',
    VIDEO: '400',
  },
  [BreOrderType.RESIDENTIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
};

export default class BreAerialJob extends BreJob<typeof BreAerialConfig> {
  get performable() {
    return BreAerialConfig;
  }

  get configurable() {
    return true;
  }

  get submission() {
    return Boolean(this.metadata.video);
  }

  get deliverable() {
    return DeliverableType.AERIAL;
  }

  get editing() {
    return this.metadata.video
      ? !this.metadata.self_edited
      : super.editing;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreAerialConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: '5',
    };
  }

  get media() {
    return {
      persona: PersonaType.PROVIDER,
      microsite: MicrositeFileType.GALLERY,
    };
  }

  onsite(): number {
    let onsite = 0;

    if (this.metadata.photos) {
      onsite += this.metadata.photos === '5' ? 15 : 30;
    }

    if (this.metadata.twilight) {
      onsite += 30;
    }

    if (this.metadata.video) {
      onsite += 30;
    }

    if (this.metadata.fpv) {
      onsite += this.metadata.fpv === 'outside' ? 30 : 60;
    }

    return onsite;
  }

  revenueLines(): TenantJobLine[] {
    const {
      COMBO,
      FPV_BOTH,
      FPV_OUTSIDE,
      PHOTOS_10,
      PHOTOS_5,
      POI,
      RUSH,
      TWILIGHT,
      VIDEO,
    } = BreAerialRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.metadata.photos) {
      const rate = match(this.metadata.photos)
        .with('5', () => PHOTOS_5)
        .with('10', () => PHOTOS_10)
        .exhaustive();

      lines.push({
        id: 'photos',
        description: 'Photos',
        amount: new Big(rate).times(this.extraFee),
      });
    }

    if (this.metadata.video) {
      lines.push({
        id: 'video',
        description: 'Video',
        amount: new Big(VIDEO),
      });
    }

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: 'Twilight Photos',
        amount: new Big(TWILIGHT).times(this.extraFee),
      })
    }

    if (this.metadata.fpv) {
      const outside = this.metadata.fpv === 'outside';

      lines.push({
        id: 'fpv',
        description: `First-Person View (${outside ? 'Outside' : 'Inside + Outside'})`,
        amount: new Big(outside ? FPV_OUTSIDE : FPV_BOTH),
      });
    }

    if (this.metadata.video && this.metadata.photos && this.metadata.photos === '10') {
      lines.push({
        id: 'combo',
        description: 'Aerial Combo Discount',
        amount: new Big(COMBO).minus(PHOTOS_10).minus(VIDEO),
        discount: true,
      });
    }

    if (this.order.metadata.rush) {
      lines.push({
        id: 'rush',
        description: 'Rush Fee',
        amount: new Big(RUSH),
      });
    }

    if (this.metadata.poi && this.metadata.poi.length > 0) {
      lines.push({
        id: 'poi',
        description: 'Points of Interest',
        amount: new Big(POI).times(this.metadata.poi.length),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const {
      COMBO,
      FPV_BOTH,
      FPV_OUTSIDE,
      PHOTOS_10,
      PHOTOS_5,
      VIDEO,
    } = BreAerialRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.metadata.photos && this.metadata.photos === '10' && this.metadata.video) {
      lines.push({
        id: 'combo',
        description: 'Photos + Video',
        amount: new Big(COMBO).times(this.metadata.self_edited ? 0.5 : 0.7),
      });
    } else {
      if (this.metadata.photos) {
        const rate = match(this.metadata.photos)
          .with('5', () => PHOTOS_5)
          .with('10', () => PHOTOS_10)
          .exhaustive();

        lines.push({
          id: 'photos',
          description: 'Photos',
          amount: new Big(rate).times(0.65),
        });
      }

      if (this.metadata.video) {
        lines.push({
          id: 'video',
          description: 'Video',
          amount: new Big(VIDEO).times(this.metadata.self_edited ? 0.5 : 0.7),
        });
      }
    }

    if (this.metadata.fpv) {
      const outside = this.metadata.fpv === 'outside';

      lines.push({
        id: 'fpv',
        description: `First-Person View (${outside ? 'Outside' : 'Inside + Outside'})`,
        amount: new Big(outside ? FPV_OUTSIDE : FPV_BOTH).times(0.65),
      });
    }

    return lines;
  }
}
