import FormGroup from '~/components/form/FormGroup';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';

export default function BreBoostForm() {
  return (
    <FormGroup>
      <BreBoostFields />
    </FormGroup>
  );
}
