import { TenantJobLine, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { DeliverableType } from '~common/model/Deliverable';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';

export default class BreFloorplanJob extends BreJob<typeof BreFloorplanConfig> {
  get performable() {
    return BreFloorplanConfig;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.FLOORPLAN;
  }

  get floorplanRevenue(): Big {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const { sqft, type } = this.order.metadata;
  
      return type === BreOrderType.COMMERCIAL
        ? new Big('0.03').times(sqft).add(40)
        : new Big('0.02').times(sqft).add(40);
    }

    return new Big('0');
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreFloorplanConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP
    };
  }

  revenueLines(): TenantOrderLine[] {
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const lines: TenantOrderLine[] = [];

      if (this.order.metadata.sqft > 0) {
        lines.push({
          id: '2d_floorplan',
          description: '2D Floorplan',
          amount: this.floorplanRevenue,
          discountable: true,
        });

        if (this.matchSocialPackage) {
          lines.push({
            id: '2d_floorplan_package',
            description: 'Social Media Package 2D Floorplan Discount',
            amount: this.floorplanRevenue.times(-0.1),
            discount: true,
          });
        }
      }
  
      if (this.order.jobs.length < 2) {
        lines.push({
          id: 'standalone_fee',
          description: '2D Standalone Fee',
          amount: new Big('50'),
        });
      }
  
      return lines;
    }

    return [];
  }

  expenseLines(): TenantJobLine[] {
    
    if (this.order.metadata.type !== BreOrderType.MARKETING) {
      const { sqft, type } = this.order.metadata;
      const lines: TenantJobLine[] = [];

      if (this.order.metadata.sqft > 0) {
        lines.push({
          id: 'floorplan',
          description: '2D Floorplan',
          amount: type === BreOrderType.COMMERCIAL
            ? new Big('0.02').times(sqft).add(-14)
            : new Big('0.01').times(sqft).add(5),
        });
      }
  
      if (this.order.jobs.length < 2) {
        lines.push({
          id: 'standalone_fee',
          description: '2D Standalone Fee',
          amount: new Big('50'),
        });
      }

      return lines;
    }

    return [];
  }
}
