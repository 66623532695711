import { useState } from 'react';
import { format_money } from '~/components/Money';
import { FormHorizontal } from '~/components/form/layout';
import InputCheckbox from '~/components/input/InputCheckbox';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';

interface BrePhotosExtraProps {
  context: BreOrderContext;
}

export default function BrePhotosExtra({ context }: BrePhotosExtraProps) {
  const photos = useZodFormFieldSingleValue('photos');
  const extra = useZodFormFieldSingleValue('extra');

  const [agree, setAgree] = useState(false);

  const job = new BrePhotosJob(context, undefined, {
    version: FIRST_VERSION_TIMESTAMP,
    photos: Number(photos) || 0,
    extra: Number(extra),
  });

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <label className="flex gap-2 font-medium cursor-pointer">
          <InputCheckbox checked={agree} onChange={setAgree} />
          Don't know which photo package to choose?
        </label>

        {agree && (
          <span className="font-bold whitespace-nowrap">
            Up to {format_money(job.extrasRevenue.toFixed(2))}
          </span>
        )}
      </div>
      <span className="text-sm text-gray-600">
        Let our photographer CHOOSE TO GO UP TO THE NEXT PACKAGE ONLY IF YOUR
        PACKAGE OPTION ISN'T LARGE ENOUGH. You accept additional charges if
        more photos than indicated by your chosen package is necessary to
        showcase the property.
      </span>
      {agree && (
        <FormHorizontal name="extra" label="Max Photos">
          <ZodFieldInput name="extra" type="number" default='5' />
        </FormHorizontal>
      )}
    </div>
  )
}
