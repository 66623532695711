import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreBoostAddOn } from '~/tenants/bre/performable/BreBoostAddOn';
import { BreWebsiteRate } from '~/tenants/bre/performable/website/BreWebsiteJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const TYPE_OPTS: InputSelectOption[] = [
  { label: 'Single Property Website', value: 'website' },
  { label: 'Subscription Renewal', value: 'renewal' },
];

export default function BreWebsiteForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { DOMAIN } = BreWebsiteRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="type" label="Type">
        <ZodFieldSelect name="type" options={TYPE_OPTS} />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="domain"
          title="Custom Domain"
          description="Add a custom domain to the website"
          image="" // ? what image should we use here
          persona={persona}
          cost={DOMAIN}
        />
        <BreBoostAddOn name="boost" persona={persona} />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
