import { TenantOrder } from '../../common/TenantOrder';
import { DELIVERY_FEES, TRAVEL_FEES } from '../data';
import { NotificationType } from '~/lib/enum';
import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BrePrintFlyersJob from '~/tenants/bre/performable/print/flyer/BrePrintFlyersJob';
import BrePrintBrochuresJob from '~/tenants/bre/performable/print/brochure/BrePrintBrochuresJob';
import BrePrintPostcardsJob from '~/tenants/bre/performable/print/postcard/BrePrintPostcardsJob';
import BreAerialJob from '~/tenants/bre/performable/aerial/BreAerialJob';
import BreCinematicJob from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import BrePhotosJob from '~/tenants/bre/performable/photos/BrePhotosJob';
import BreMatterportJob from '~/tenants/bre/performable/matterport/BreMatterportJob';

import { DateTime } from 'luxon';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreJob from '~/tenants/bre/model/BreJob';
import { Holiday } from '~common/holidays/holidays';

// TODO:
/*Design fee for customization of an existing template must be an additional line item on the flyer job, not a separate "design only" job

Social media boosts should be their own job, not part of the virtual tour/single property website

Delivery addresses should be required for all print materials and delivery fee should be automatically added (this includes postcards with no mailing). The delivery fees will vary since the minimum fee is included in the cost of the flyers, but not included for the signs or postcards.

Sign riders - put a note on there, informing them that sign riders have a 3 business day turnaround time (and UPS adds an extra day)

Let agents know that content writing is available for a fee and, if they want to use this service, will need to be requested in advance to avoid delay in delivery*/

export class BreOrder extends TenantOrder<BreOrderContext, BreJob> {
  sendNotification(type: NotificationType): {
    attachments: string[];
  } {
    const attachments: string[] = [];

    if (type === NotificationType.ORDER_RECEIVED) {
      const copywriting = this.jobs.some((job) => {
        (job instanceof BrePrintFlyersJob || job instanceof BrePrintBrochuresJob) && job.metadata.copywriting;
      });

      if (copywriting) {
        // TODO
        attachments.push('TODO');
      }

      const brochure = this.jobs.some(
        (p) => p instanceof BrePrintBrochuresJob || p instanceof BrePrintFlyersJob || p instanceof BrePrintPostcardsJob,
      );

      if (brochure) {
        // TODO
        attachments.push('TODO');
      }

      const print = this.jobs.some((p) => p.isPrint());

      if (print) {
        // TODO
        attachments.push('TODO');
      }

      const video = this.jobs.some((p) => p instanceof BreCinematicJob || p instanceof BreAerialJob);

      if (video) {
        // TODO
        attachments.push('TODO');
      }

      const ready = this.jobs.some(
        (p) => p instanceof BrePhotosJob || p instanceof BreMatterportJob || p instanceof BreCinematicJob,
      );

      if (ready) {
        // TODO
        attachments.push('TODO');
      }
    }

    return { attachments };
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    const print = this.jobs.filter((p) => p.isPrint());

    for (const _perf of print) {
      //  TODO: multiply revenue by 9.125%
    }

    if (this.context.metadata.type === BreOrderType.COMMERCIAL) {
      // TODO: add 30%
    }

    if (this.context.metadata.onsite) {
      // TODO: allow non-print orders to have delivery
      const hasFlyer = this.jobs.some((p) => p instanceof BrePrintFlyersJob);

      if (!hasFlyer) {
        const deliver = this.jobs.some((p) => p.isDelivery());

        if (deliver) {
          for (const [fee, postals] of Object.entries(DELIVERY_FEES)) {
            if (postals.includes(this.context.metadata.onsite.address.zip)) {
              lines.push({
                id: 'delivery',
                description: 'Delivery Fee - UPS',
                amount: new Big(fee),
              });
            }
          }
        }
      }

      if (
        this.context.appointments.some((a) => DateTime.fromISO(a.start).weekday >= 6) &&
        !this.context.metadata.onsite.excludeWeekendFees
      ) {
        lines.push({
          id: 'weekend',
          description: 'Weekend Fee',
          amount: new Big(100),
        });
      }

      if (
        this.context.appointments.some(
          (a) =>
            a.holiday &&
            [
              Holiday.CHRISTMAS,
              Holiday.CHRISTMAS_EVE,
              Holiday.LABOR_DAY,
              Holiday.THANKSGIVING,
            ].includes(a.holiday),
        )
      ) {
        lines.push({
          id: 'holiday',
          description: 'Holiday Fee',
          amount: new Big(100),
        });
      }

      if (!this.context.metadata.onsite.excludeTravelFees) {
        for (const [fee, postals] of Object.entries(TRAVEL_FEES)) {
          let included = false;

          if ('condition' in postals) {
            if (!postals.condition(this.context)) {
              continue;
            }

            included = postals.postals.includes(this.context.metadata.onsite.address.zip);
          } else {
            included = postals.includes(this.context.metadata.onsite.address.zip);
          }

          if (included) {
            lines.push({
              id: 'travel',
              description: 'Travel Fee',
              amount: new Big(fee),
            });
          }
        }
      }
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    if (this.context.metadata.onsite && !this.context.metadata.onsite.excludeTravelFees) {
      for (const [fee, postals] of Object.entries(TRAVEL_FEES)) {
        let included = false;

        if ('condition' in postals) {
          if (!postals.condition(this.context)) {
            continue;
          }

          included = postals.postals.includes(this.context.metadata.onsite.address.zip);
        } else {
          included = postals.includes(this.context.metadata.onsite.address.zip);
        }

        // TODO: split among providers in context.appointments
        if (included) {
          lines.push({
            id: 'travel',
            description: 'Travel Fee',
            amount: new Big(fee),
          });
        }
      }
    }

    return lines;
  }
}
