import { useEffect } from 'react';

export default function useUserGuiding() {
  useEffect(() => {
    // @ts-ignore
    window['userGuidingLayer'] = window['userGuidingLayer'] || [];
    var f = document.getElementsByTagName('script')[0];
    var k = document.createElement('script');
    k.async = true;
    k.src = 'https://static.userguiding.com/media/user-guiding-C5Y968508OEID-embedded.js';
    f.parentNode?.insertBefore(k, f);
    // @ts-ignore
    if (window['userGuiding']) return;
    // @ts-ignore
    var ug = (window['userGuiding'] = { q: [] }) as any;
    ug.c = function (n: unknown) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    var m = ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist'];
    for (var j = 0; j < m.length; j += 1) {
      ug[m[j]] = ug.c(m[j]);
    }
  }, []);
}
