import z from 'zod';
import * as v from 'valibot';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { boostSchema } from '~/tenants/bre/performable/boost/BreBoostConfig';
import { voiceSchema } from '~/tenants/bre/performable/common';

export default createPerformableConfig(
  'social',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      aerial_photos: coerceBoolean().optional(),
      aerial_video: coerceBoolean().optional(),
      intro: voiceSchema.optional(),
      narration: voiceSchema.optional(),
      voiceover: voiceSchema.optional(),
      twilight: coerceBoolean().optional(),
      boost: boostSchema.optional(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Social Media Video',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);
