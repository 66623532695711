import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreCinematicRate } from '~/tenants/bre/performable/cinematic/BreCinematicJob';
import { VOICE_OPTS } from '~/tenants/bre/performable/common';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRadioCards } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreCinematicForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const {
    AERIAL_PHOTOS,
    INTRO,
    NARRATION,
    VOICEOVER,
    TWILIGHT,
  } = BreCinematicRate[context.metadata.type];

  return (
    <FormGroup>
      {context.metadata.type === BreOrderType.COMMERCIAL && (
        <FormHorizontal name="duration" label="Duration (mins)">
          <ZodFieldInput name="duration" type="number" />
        </FormHorizontal>
      )}

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="aerial_upgrade"
          title="Aerial Upgrade"
          description="Adds aerial to the Cinematic Video (Must meet FAA area rules for the address)"
          image="" // ? what should we use here
          persona={persona}
        />
        <TenantPackageAddOnRadioCards
          name="intro"
          title="Agent Intro"
          description="Adding an agent intro/ending to the video"
          image="" // ? what should we use here
          persona={persona}
          cost={INTRO}
          options={VOICE_OPTS}
          cols={2}
        />
        <TenantPackageAddOnRadioCards
          name="narration"
          title="Agent Narration"
          description="Adding a voice-over to the video"
          image="" // ? what should we use here
          persona={persona}
          cost={NARRATION}
          options={VOICE_OPTS}
          cols={2}
        />
        <TenantPackageAddOnRadioCards
          name="voiceover"
          title="Kwasi Voice Over"
          description="Add a 3 minute Kwasi voice over"
          image="" // ? what should we use here
          persona={persona}
          cost={VOICEOVER}
          options={VOICE_OPTS}
          cols={2}
        />
        <TenantPackageAddOnCheckbox
          name="twilight"
          title="Twilight Transitions"
          description="Day-to-Dusk Twilight Transitions"
          image="" // ? what should we use here
          persona={persona}
          cost={TWILIGHT}
        />
        <TenantPackageAddOnCheckbox
          name="aerial_photos"
          title="5 Aerial Photos"
          description="Add 5 additional photos to the package"
          image="" // ? what should we use here
          persona={persona}
          cost={AERIAL_PHOTOS}
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

/*
 * Cinematic Style Films will engage potential buyers and bring you new clients in the future! Our new Cinematic Style Films are not just your run-of-the-mill walk-throughs videos anymore!
 *
 * Our footage is stabilized by top-of-the-line handheld gimbals and cameras. Although having the shiniest and the highest quality gear may not be the most important aspect of what we do, having the best tools ensures that our production value is on par with our storytelling ability. Our well-produced and edited Cinematic Videos will engage potential buyers so they will want to see your listing in person.
 *
 * Our videos are edited by a Professional Editing Team and can take anywhere from 48-72 hours from the shoot date depending on the package you choose. There is as much to editing as there is to filming!
 */

/*
Cinematic Style Films will engage potential buyers and bring you new clients in the future! Our new Cinematic Style Films are not just your run-of-the-mill walk-throughs videos anymore!
Just choose if you want to include your video with or without Aerial footage..
 */
