import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreStagingRate } from '~/tenants/bre/performable/staging/BreStagingJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnInput } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const STYLE_OPTS: InputSelectOption[] = [
  { label: 'Modern', value: 'modern' },
  { label: 'Scandinavian', value: 'scandinavian' },
  { label: 'Contemporary', value: 'contemporary' },
  { label: 'Hamptons', value: 'hamptons' },
  { label: 'Traditional', value: 'traditional' },
  { label: 'Farmhouse', value: 'farmhouse' },
  { label: 'Urban/Industrial', value: 'urban' },
  { label: 'Outdoor Patio', value: 'outdoor' },
];

export default function BreStagingForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { RENOVATION } = BreStagingRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="style" label="Style">
        <ZodFieldSelect name="style" options={STYLE_OPTS} />
      </FormHorizontal>
      <FormHorizontal name="quantity" label="Quantity">
        <ZodFieldInput name="quantity" type="number" />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnInput
          name="renovations"
          title="Virtual Renovation"
          description="Add extra virtual renovations"
          label="Images"
          image="" // ? what should we use here
          persona={persona}
          cost={RENOVATION} // TODO: Show $/each
        />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}
