import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreTwilightRate } from '~/tenants/bre/performable/twilight/BreTwilightJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';

const COMMERCIAL_PHOTO_OPTS: InputSelectOption[] = [
  { label: '15 Photos', value: '15' },
  { label: '25 Photos', value: '25' },
];

const PHOTO_OPTS: InputSelectOption[] = [
  { label: '8 Photos', value: '8' },
  ...COMMERCIAL_PHOTO_OPTS,
];

export default function BreTwilightForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { COPYRIGHT } = BreTwilightRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldSelect
          name="photos"
          options={
            context.metadata.type === BreOrderType.COMMERCIAL
              ? COMMERCIAL_PHOTO_OPTS
              : PHOTO_OPTS
          }
        />
      </FormHorizontal>
      <FormHorizontal name="notes" label="Notes">
        <ZodFieldInput name="notes" />
      </FormHorizontal>
      {context.metadata.type === BreOrderType.COMMERCIAL && (
        <TenantPackageAddOnCheckbox
          name="copyright"
          title="Copyright"
          description="Release photos with copyrights"
          image="" // ? what image should we use here
          persona={persona}
          cost={COPYRIGHT} // TODO: Need to show $50/each
        />
      )}
    </FormGroup>
  );
}
