import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiIguideConfig from './MpiIguideConfig';
import MpiJob from '../../model/MpiJob';

const MPI_IGUIDE_MIN_SQFT = 1000;
const MPI_IGUIDE_MIN_AMOUNT = 150;
const MPI_IGUIDE_SQFT_MULTIPLIER = 0.03;

export default class MpiIguideJob extends MpiJob<typeof MpiIguideConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiIguideConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite() {
    return 30;
  }

  get performable() {
    return MpiIguideConfig;
  }

  get configurable() {
    return true;
  }

  info(): DisplayData[] {
    const info = super.info();

    return info;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    const bySquareFootAmount =
      MPI_IGUIDE_MIN_AMOUNT +
      Math.ceil((Math.max(this.order.metadata.sqft, MPI_IGUIDE_MIN_SQFT) - MPI_IGUIDE_MIN_SQFT) / 1000) *
        1000 *
        MPI_IGUIDE_SQFT_MULTIPLIER;

    lines.push({
      amount: new Big(bySquareFootAmount),
      description: `3D IGuide`,
      id: 'iguide',
    });

    return lines;
  }
}
