import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import * as v from 'valibot';

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.union([z.literal('5'), z.literal('10')]).optional(),
      video: coerceBoolean().optional(),
      twilight: coerceBoolean().optional(),
      fpv: z.union([z.literal('outside'), z.literal('both')]).optional(),
      poi: z.array(z.object({ name: z.string(), location: z.string() })).optional(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Aerial Photo & Video',
    images: [],
    short: '',
    tenant: TenantId.BEYOND_RE_MARKETING,
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);
