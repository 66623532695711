import { FormHorizontal } from '~/components/form/layout';
import Heading from '~/components/layout/Heading';
import Sections from '~/components/layout/Sections';
import PerformableGroupForm from '~/components/performable/PerformableGroupForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { BrePackageAerialInclude, BrePackageBoostInclude, BrePackagePhotoForm, BrePackagePhotosInclude } from '~/tenants/bre/package/common';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import { AERIAL_PHOTO_OPTS } from '~/tenants/bre/performable/aerial/BreAerialForm';
import BreAerialVideo from '~/tenants/bre/performable/aerial/BreAerialVideo';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantPackageInclude, TenantPackageIncludes } from '~/tenants/common/package';

export default function BreCinematicsPackageForm() {
  return (
    <>
      <Sections>
        <Heading title="Customize your package" />
        <BrePackagePhotoForm />
        <PerformableGroupForm performable={BreFloorplanConfig} />
        <PerformableGroupForm performable={BreSocialConfig} />
        <PerformableGroupForm performable={BreCinematicConfig} />
        <PerformableGroupForm performable={BreAerialConfig}>
          <FormHorizontal name="photos" label="Aerial Photos">
            <ZodFieldRadioCards
              name="photos"
              options={AERIAL_PHOTO_OPTS}
              clearable={true}
              cols={2}
            />
          </FormHorizontal>
          <FormHorizontal name="video" label="Aerial Video">
            <BreAerialVideo />
          </FormHorizontal>
        </PerformableGroupForm>
        <PerformableGroupForm performable={BreBoostConfig}>
          <BreBoostFields />
        </PerformableGroupForm>
        <PerformableGroupForm performable={BreWebsiteConfig}>
          <ZodFieldHidden name="type" value="website" />
        </PerformableGroupForm>
      </Sections>

      <div className="border-theme-separator border-t pt-6 mt-6">
        <TenantPackageIncludes>
          <BrePackagePhotosInclude />
          <TenantPackageInclude name="2D Floorplan">
            2D floor plan
          </TenantPackageInclude>
          <TenantPackageInclude name="Social Media Video">
            Basic Ground Footage
          </TenantPackageInclude>
          <BrePackageAerialInclude />
          <BrePackageBoostInclude />
          <TenantPackageInclude name="Single Property Website Page">
            1-year subscription to a single property website page
          </TenantPackageInclude>
        </TenantPackageIncludes>
      </div>
    </>
  )
}
