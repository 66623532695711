import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import {
  coerceBoolean,
  coerceLiteralNumberRequired,
  FIRST_VERSION_TIMESTAMP,
} from '~/lib/zod';
import * as v from "valibot";
import { voiceSchema } from '~/tenants/bre/performable/common';

export default createPerformableConfig(
  'cinematic',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      aerial_upgrade: coerceBoolean().optional(),
      intro: voiceSchema.optional(),
      narration: voiceSchema.optional(),
      voiceover: voiceSchema.optional(),
      twilight: coerceBoolean().optional(),
      aerial_photos: coerceBoolean().optional(),
      duration: z.string().pipe(z.coerce.number()).or(z.number()).optional(),
      self_edited: coerceBoolean().optional(),
    }),
  },
  {
    name: 'Cinematic Video',
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
    images: [],
    submit: v.object({
      self_edited: v.boolean(),
    }),
  },
);
