import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import z from 'zod';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiVideoConfig from '~/tenants/mpi/performable/video/MpiVideoConfig';
import { MpiCustomerMetadata } from '~/tenants/mpi/model/MpiCustomer';
import { TenantOrder } from '~/tenants/common/TenantOrder';
import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { datetime } from '~/lib/datettime';
import MpiFloorplanConfig from '../performable/floorplan/MpiFloorplanConfig';
import MpiIguideConfig from '../performable/iguide/MpiIguideConfig';
import MpiVirtualStagingConfig from '../performable/staging/MpiVirtualStagingConfig';
import MpiMatterportConfig from '../performable/matterport/MpiMatterportConfig';
import MpiAerialConfig from '../performable/aerial/MpiAerialConfig';
import MpiBrochuresConfig from '../performable/brochures/MpiBrochuresConfig';

const BULK_ORDER_DISCOUNT: Record<number, number> = {
  2: 0.08,
  3: 0.1,
  4: 0.12,
  5: 0.15,
  6: 0.18,
  7: 0.21,
};

export enum MpiOrderType {
  RESIDENTIAL = 'residential',
  RENTAL = 'rental',
}

export const MpiOrderSchema = {
  [FIRST_VERSION_TIMESTAMP]: z
    .object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      sqft: z.coerce
        .number({
          required_error: 'Please provide the square footage of the property or unit.',
          invalid_type_error: 'Please provide the square footage of the property or unit.',
        })
        .min(1, { message: 'The square footage of the property or unit must be greater than 1.' })
        .optional(),
    })
    .and(
      z.discriminatedUnion('type', [
        z.object({
          type: z.literal(MpiOrderType.RESIDENTIAL),
          sqft: z.coerce.number(),
        }),
        z.object({
          type: z.literal(MpiOrderType.RENTAL),
          sqft: z.coerce.number(),
        }),
      ]),
    ),
};

export type MpiOrderMetadata = ZodVersionedMetadata<typeof MpiOrderSchema>;

export type MpiPerformableConfig =
  | typeof MpiPhotosConfig
  | typeof MpiVideoConfig
  | typeof MpiFloorplanConfig
  | typeof MpiIguideConfig
  | typeof MpiMatterportConfig
  | typeof MpiVirtualStagingConfig
  | typeof MpiAerialConfig
  | typeof MpiBrochuresConfig;

export type MpiOrderContext = TenantOrderContext<MpiOrderMetadata, MpiPerformableConfig, MpiCustomerMetadata>;

export class MpiOrder extends TenantOrder<MpiOrderContext> {
  info(): Array<DisplayData> {
    const info = super.info();

    const type = (() => {
      switch (this.context.metadata.type) {
        case MpiOrderType.RESIDENTIAL:
          return 'Real estate property';
        case MpiOrderType.RENTAL:
          return 'Rental property';
        default:
          return undefined;
      }
    })();

    if (type) {
      info.push({
        name: 'Order Type',
        invoice: true,
        value: type,
        provider: true,
        schedule: true,
        customer: true,
      });
    }

    if (this.context.metadata.sqft) {
      info.push({
        name: 'Square Feet',
        invoice: true,
        provider: true,
        customer: true,
        schedule: true,
        value: this.context.metadata.sqft.toString(),
      });
    }

    return info;
  }

  get hasTravelCostJob() {
    return this.context.jobs.some((job) =>
      [
        MpiPhotosConfig.id,
        MpiVideoConfig.id,
      ].includes(job.performable_id as any),
    );
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    if (this.hasTravelCostJob && !this.hasAppointments) {
      lines.push({
        id: 'travel',
        description: 'Travel Compensation',
        amount: new Big(5),
        fee: 'travel',
      });
    }

    if (this.requestedOnWeekend && this.context.appointments.length === 0) {
      lines.push({
        id: 'weekend-requested',
        description: 'Weekend Fee',
        amount: new Big(125),
        fee: 'weekend',
      });
    }

    for (const appointment of this.context.appointments) {
      const isOnWeekend = datetime(appointment.start, this.location.timezone).weekday >= 6;

      if (isOnWeekend) {
        lines.push({
          id: `weekend-${appointment.id}`,
          description: 'Weekend Fee',
          amount: new Big(125),
          fee: 'weekend',
        });
      }

      lines.push({
        id: `travel-${appointment.id}`,
        description: 'Travel Compensation',
        amount: new Big(5),
      });
    }

    if (this.jobs.some((j) => j.performable.id === MpiAerialConfig.id) && this.jobs.length > 1) {
      lines.push({
        id: `aerial-discount`,
        description: 'Aerial discount',
        amount: new Big(50).times(-1),
      });
    }

    if (this.jobs.length > 1) {
      const discountRatio = new Big(BULK_ORDER_DISCOUNT[Math.min(this.jobs.length, 7)]);
      const jobsRevenue = this.jobs.reduce((prev, curr) => prev.plus(curr.revenue(false)), new Big(0));

      lines.push({
        id: 'bulk-discount',
        description: `Order Discount (${this.jobs.length} Services: ${discountRatio.times(100).toFixed(0)}%)`,
        amount: discountRatio.times(jobsRevenue).times(-1),
        discountable: false,
      });
    }

    return lines;
  }
}
