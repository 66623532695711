import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputRadioCardsOption } from '~/components/input/InputRadioCards';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreAerialFPVOddOn from '~/tenants/bre/performable/aerial/BreAerialFPVAddOn';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialJob';
import BreAerialVideo from '~/tenants/bre/performable/aerial/BreAerialVideo';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRepeat } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export const AERIAL_PHOTO_OPTS: InputRadioCardsOption[] = [
  { title: `5 Photos`, description: 'Up to 5 aerial photos', value: '5' },
  { title: `10 Photos`, description: 'Up to 10 aerial photos', value: '10' },
];

export default function BreAerialForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { POI, TWILIGHT, VIDEO } = BreAerialRate[context.metadata.type];

  return (
    <FormGroup>
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldRadioCards
          name="photos"
          options={AERIAL_PHOTO_OPTS}
          clearable={true}
          cols={2}
        />
      </FormHorizontal>
      <FormHorizontal name="video" label="Video">
        <BreAerialVideo cost={VIDEO} />
      </FormHorizontal>

      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="twilight"
          title="Aerial Twilight"
          description="Add 5 aerial twilight photos"
          image="" // ? what image should we use here
          persona={persona}
          cost={TWILIGHT}
        />
        <BreAerialFPVOddOn
          persona={persona}
          orderType={context.metadata.type}
        />
        <TenantPackageAddOnRepeat
          name="poi"
          title="Points of Interest"
          description="Points of Interest Add-ons"
          image="" // ? what image should we use here
          persona={persona}
          cost={POI}
        >
          <div className="flex gap-4">
            <ZodFieldInput name="name" placeholder="Name" />
            <ZodFieldInput name="location" placeholder="Directions" />
          </div>
        </TenantPackageAddOnRepeat>
      </TenantPerformableAddOns>
    </FormGroup>
  )
}

/*
POI only if video


Our FAA approved aerial imaging offers unique and stunning overhead views of your listing.

Our Aerial photos are shot in High Resolution using multiple exposures that are then “layered” over each other to form an extremely natural-looking and detail-rich images.

We can add boundary lines, arrows or other symbols to show proximity to well known locations from your property (for an extra fee)
 */

/*
Property to be photographed or filmed will be "Photo Ready" upon Photographers arrival, otherwise there will be a Same Day cancellation or delay fee.

Same-Day Cancellation = $125 (Less than 24 Hour Notice) or $175 (Less Than 3 Hour Notice).
Delay Fee = $50.00 per every 15 minutes up to 30 minutes
 */
