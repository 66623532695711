import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import z from 'zod';

export default createPerformableConfig(
  'aerial',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    }),
  },
  {
    name: 'Aerial Photography',
    // TODO: Joel: Revisit this
    thumbnail: 'tenant/twt/aerial/mini.jpg',
    short: 'High resolution aerial imagery to showcase your property within FAA guidelines.',
    tenant: TenantId.MPI,
    images: [],
  },
);
