import MpiPhotosTwilight from './MpiPhotosTwilight';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';
import MpiPhotosCommunity from './MpiPhotosCommunity';

export default function MpiPhotosAddOns(props: TenantPerformableFormProps<MpiOrderContext>) {
  return (
    <>
      <MpiPhotosTwilight {...props} />
      <MpiPhotosCommunity {...props} />
    </>
  );
}
