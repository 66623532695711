import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { MpiTwilightPhotoType } from './MpiPhotosTwilight';
import { GeocodedAddressSchema } from '~/lib/model';

export enum MpiPhotosType {
  STANDARD = 'standard',
}

export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z
      .object({
        version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
        photos: z.coerce.number().optional(),
        extra_photos: z.coerce.number().optional(),
        twilight: coerceBoolean().optional(),
        twilight_type: z.nativeEnum(MpiTwilightPhotoType).optional(),
        twilight_photos: z.coerce.number().optional(),
        community: coerceBoolean().optional(),
        community_photos: z.coerce.number().optional(),
        community_adress: z.array(GeocodedAddressSchema).optional(),
      })
      .and(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(MpiPhotosType.STANDARD),
          }),
        ]),
      ),
  },
  {
    name: 'Photography',
    images: [],
    short:
      'Our most popular service! Includes 5-40 photos, delivered in 24 hours or less! Includes labor, editing and licensing fees.',
    tenant: TenantId.MPI,
  },
);
