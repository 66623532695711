import { FormHorizontal } from '~/components/form/layout';
import { usePackageFormContext } from '~/components/performable/PackageForm';
import PerformableGroupForm, { usePerformableName } from '~/components/performable/PerformableGroupForm';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BrePhotosExtra from '~/tenants/bre/performable/photos/BrePhotosExtra';
import { PHOTOS_OPTS } from '~/tenants/bre/performable/photos/BrePhotosForm';
import { TenantPackageInclude } from '~/tenants/common/package';

export function BrePackagePhotoForm() {
  const { orderContext } = usePackageFormContext();

  return (
    <PerformableGroupForm performable={BrePhotosConfig}>
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldSelect name="photos" options={PHOTOS_OPTS} />
      </FormHorizontal>
      <FormHorizontal label="Extra Photos">
        <BrePhotosExtra context={orderContext as BreOrderContext} />
      </FormHorizontal>
    </PerformableGroupForm>
  );
}

export function BrePackagePhotosInclude() {
  const name = usePerformableName(BrePhotosConfig);
  const photos = useZodFormFieldSingleValue(`${name}.metadata.photos`);

  return (
    <TenantPackageInclude name="Ground Photography">
      {`${photos} Photos`}
    </TenantPackageInclude>
  );
}

export function BrePackageBoostInclude() {
  const name = usePerformableName(BreBoostConfig);
  const reach = useZodFormFieldSingleValue(`${name}.metadata.reach`);

  return (
    <TenantPackageInclude name="Social Media Boost">
      {`Up to ${reach} potential reach`}
    </TenantPackageInclude>
  )
}

export function BrePackageAerialInclude() {
  const name = usePerformableName(BreAerialConfig);
  const photos = useZodFormFieldSingleValue(`${name}.metadata.photos`);
  const video = useZodFormFieldSingleValue(`${name}.metadata.video`) === 'true';

  return (
    <TenantPackageInclude name={`Aerial Photos ${video ? "& Video" : ""}`}>
      {`${photos} aerial photos ${video ? '& aerial video clip' : ''}`}
    </TenantPackageInclude>
  );
}
