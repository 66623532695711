import Heading from '~/components/layout/Heading';
import Sections from '~/components/layout/Sections';
import PerformableGroupForm from '~/components/performable/PerformableGroupForm';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import { BrePackageBoostInclude, BrePackagePhotoForm, BrePackagePhotosInclude } from '~/tenants/bre/package/common';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import { BreBoostFields } from '~/tenants/bre/performable/boost/BreBoostFields';
import BreDuskConfig from '~/tenants/bre/performable/dusk/BreDuskConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantPackageInclude, TenantPackageIncludes } from '~/tenants/common/package';

export default function BreSocialPackageForm() {
  return (
    <>
      <Sections>
        <Heading title="Customize your package" />
        <BrePackagePhotoForm />
        <PerformableGroupForm performable={BreBoostConfig}>
          <BreBoostFields />
        </PerformableGroupForm>
        <PerformableGroupForm performable={BreWebsiteConfig}>
          <ZodFieldHidden name="type" value="website" />
        </PerformableGroupForm>
        <PerformableGroupForm performable={BreFloorplanConfig} />
        <PerformableGroupForm performable={BreSocialConfig} />
        <PerformableGroupForm performable={BreDuskConfig}>
          <ZodFieldHidden name="quantity" value="1" />
        </PerformableGroupForm>
      </Sections>

      <div className="border-theme-separator border-t pt-6 mt-6">
        <TenantPackageIncludes>
          <BrePackagePhotosInclude />
          <TenantPackageInclude name="Single Property Website Page">
            1-year subscription to a single property website page
          </TenantPackageInclude>
          <TenantPackageInclude name="2D Floorplan">
            2D floor plan
          </TenantPackageInclude>
          <BrePackageBoostInclude />
          <TenantPackageInclude name="Social Media Video">
            Basic Ground Footage
          </TenantPackageInclude>
          <TenantPackageInclude name="Day-to-Dusk Edit">
            One complementary Day-to-Dusk photo edit.
          </TenantPackageInclude>
        </TenantPackageIncludes>
      </div>
    </>
  );
}
