import { useState } from 'react';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { FormHelperView, FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import InputCheckbox from '~/components/input/InputCheckbox';
import { format_money } from '~/components/Money';
import MpiPhotosJob from './MpiPhotosJob';

export default function MpiPhotosExtra() {
  const extraPhotos = Number(useZodFormFieldSingleValue('extra_photos') ?? 0);

  const [agree, setAgree] = useState(false);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <label className="flex gap-2 font-medium cursor-pointer">
          <InputCheckbox checked={agree} onChange={setAgree} />
          Want extra photos?
        </label>

        {agree && (
          <p className="font-bold whitespace-nowrap">
            Up to {format_money(MpiPhotosJob.extraPhotos(extraPhotos).toFixed(2))}
          </p>
        )}
      </div>
      <p className="text-sm text-gray-600">
        Let our photographer choose if you need extra photos. You accept additional charges if more photos than
        indicated by your chosen package is necessary to showcase the property.
      </p>
      {agree && (
        <FormHorizontal name="extra_photos" label="Max Photos">
          <ZodFieldInput name="extra_photos" type="number" default="5" />
        </FormHorizontal>
      )}
    </div>
  );
}
