import Heading from '~/components/layout/Heading';
import React, { useCallback, useEffect, useId, useState } from 'react';
import IconCheck from '~/components/icons/streamline/line/IconCheck';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';
import { format_money } from '~/components/Money';
import ResponsiveImage from '~/components/ResponsiveImage';
import {
  useZodFormFieldArray,
  useZodFormFieldMultiple,
  useZodFormFieldSingle,
  ZodNestedForm,
} from '~/components/zod/ZodForm';
import InputCheckbox from '~/components/input/InputCheckbox';
import Link from '~/components/interactive/Link';
import IconAdd from '~/components/icons/streamline/line/IconAdd';
import IconDelete from '~/components/icons/streamline/line/IconDelete';
import Button from '~/components/interactive/Button';
import Modal, { ModalHeader } from '~/components/interactive/Modal';
import ZodFieldHidden from '~/components/zod/ZodFieldHidden';
import IconVisible from '~/components/icons/streamline/line/IconVisible';
import classNames from 'classnames';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldCheckboxMultiple from '~/components/zod/ZodFieldCheckboxMultiple';
import { PersonaType } from '~/tenants/common/TenantPersona';
import InputText from '~/components/input/InputText';
import IconArrowDown from '~/components/icons/streamline/IconArrowDown';
import IconArrowUp from '~/components/icons/streamline/IconArrowUp';
import { InputRadioCardsOption } from '~/components/input/InputRadioCards';
import ZodFieldRadioCards from '~/components/zod/ZodFieldRadioCards';

interface TenantPackageAddOnTemplateProps {
  image: string;
  lightbox?: boolean;
  disabled?: boolean;
  description: string;
  persona: PersonaType;
  children?: React.ReactNode;
  title: string;
}

export function TenantPackageAddOnTemplate({
  children,
  image,
  name,
  title,
  lightbox,
  cost,
  enable,
  onClick,
  disabled,
  persona,
  htmlFor,
  description,
}: TenantPackageAddOnTemplateProps & {
  name?: string;
  htmlFor?: string;
  enable?: React.ReactNode;
  cost?: string;
  onClick?: () => void;
}) {
  const [show, setShow] = useState(false);

  return (
    <div
      className={classNames('flex max-sm:flex-col items-center sm:items-start max-sm:space-y-4 sm:space-x-6', {
        'grayscale pointer-events-none opacity-50': disabled,
      })}
    >
      {persona === PersonaType.CUSTOMER && (
        <div className="rounded-lg overflow-hidden flex-shrink-0 ring-theme-separator ring-1">
          <Modal
            show={show}
            onClose={() => {
              setShow(false);
            }}
          >
            <ResponsiveImage
              path={image}
              width={900}
              className="rounded-theme"
              onClick={() => {
                setShow(false);
              }}
            />
          </Modal>
          <ResponsiveImage
            path={image}
            width={200}
            height={133}
            onClick={() => {
              if (lightbox) {
                setShow(true);
              }

              onClick?.();
            }}
            className={classNames({
              'opacity-50': disabled,
              'cursor-pointer': onClick || lightbox,
              'hover:scale-105 transition': onClick,
            })}
          />
        </div>
      )}
      <div className="space-y-4 w-full">
        <div>
          <div className="flex items-center leading-none select-none">
            {enable && <div className="w-8 flex-shrink-0">{enable}</div>}
            <label className="text-base font-medium cursor-pointer w-full" htmlFor={htmlFor ?? name}>
              {title}
            </label>
            {cost && <p className="font-bold text-base whitespace-nowrap">{cost}</p>}
          </div>
          <div className="pt-2 max-sm:pr-4">
            {persona === PersonaType.CUSTOMER && <p className="text-sm text-gray-600">{description}</p>}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

function NeighborhoodSelection({ name, groups }: { name: string; groups: TenantPackageGroup[] }) {
  const [preview, setPreview] = useState<string>();
  const [expanded, setExpanded] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const searched = (search ? groups.filter((g) => g.name.toLowerCase().includes(search.toLowerCase())) : groups).slice(
    0,
    10,
  );

  return (
    <div className="relative rounded-theme overflow-hidden bg-white">
      {preview && (
        <div className="p-inner space-y-3 flex flex-col items-center justify-between absolute w-full h-full z-100 bg-white overflow-hidden rounded-theme">
          <div className="flex-1 flex items-center w-full">
            <ResponsiveImage path={preview} round className="max-h-80 w-full" width={650} height={300} />
          </div>
          <div className="flex w-full justify-between space-x-unrelated">
            <Button type="button" variant="outline" onClick={() => setPreview(undefined)}>
              Back to Gallery
            </Button>
          </div>
        </div>
      )}
      <ModalHeader>Neighborhood Search</ModalHeader>
      <div className="p-4 space-y-2 border-theme-separator border-b">
        <p className="text-sm font-medium">
          There are {groups.length} neighborhoods for you to choose from, narrow the list by typing in the search bar
          below.
        </p>
        <InputText
          placeholder="Search by postal code or name"
          onChange={(v) => {
            setSearch(v ?? '');
          }}
        />
      </div>
      <div className="bg-gray-50 overflow-y-scroll h-96 divide-y divide-theme-separator select-none">
        {searched.map((group) => (
          <div key={group.name}>
            <div
              className="py-2 px-4 flex items-center space-x-2 hover:bg-gray-100 cursor-pointer"
              onClick={(e) => {
                if (e.target instanceof Element && e.target.tagName.toLowerCase() === 'input') {
                  return;
                }

                expanded.includes(group.name)
                  ? setExpanded(expanded.filter((e) => e !== group.name))
                  : setExpanded([...expanded, group.name]);
              }}
            >
              <ZodFieldCheckboxMultiple name={name} value={group.name} />
              <p className="font-medium flex-1">{group.name}</p>
              <div className="w-6 h-3">{expanded.includes(group.name) ? <IconArrowUp /> : <IconArrowDown />}</div>
            </div>
            {expanded.includes(group.name) && (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 p-inner">
                {group.images.map((image) => (
                  <ResponsiveImage
                    path={image}
                    onClick={() => {
                      setPreview(image);
                    }}
                    className="cursor-pointer hover:scale-105 transition rounded-theme"
                    width={150}
                    height={100}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

// TODO: Use this across the other functions
export function GalleryExamples({
  name,
  persona,
  images,
}: {
  name: string;
  persona: PersonaType;
  images: [string, ...string[]];
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      {persona === PersonaType.CUSTOMER && (
        <div className="pt-2">
          <Link onClick={() => setShow(true)} icon={<IconVisible />}>
            View Examples
          </Link>
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <GallerySingle name={name} images={images} />
      </Modal>
    </>
  );
}

function GallerySingle({
  name,
  images,
  onSelect,
}: {
  name: string;
  images: [string, ...string[]];
  onSelect?: (image: string) => void;
}) {
  const [preview, setPreview] = useState<string>();
  const [_name, selected, setSelected] = useZodFormFieldMultiple(name);

  return (
    <div className="relative rounded-theme overflow-hidden bg-white">
      {preview && (
        <div className="p-inner space-y-3 flex flex-col items-center justify-between absolute w-full h-full z-100 bg-white overflow-hidden rounded-theme">
          <div className="flex-1 flex items-center w-full">
            <ResponsiveImage path={preview} round className="max-h-80 w-full" width={650} height={300} />
          </div>
          <div className="flex w-full justify-between space-x-unrelated">
            <Button type="button" variant="outline" onClick={() => setPreview(undefined)}>
              {onSelect ? 'Cancel' : 'Back to Gallery'}
            </Button>
            {onSelect && (
              <Button
                onClick={() => {
                  setPreview(undefined);

                  onSelect?.(preview);
                }}
              >
                Select Photo
              </Button>
            )}
          </div>
        </div>
      )}
      <ModalHeader>
        Scroll through the following images and click to preview{onSelect ? ' and select' : ''}.
      </ModalHeader>
      <div className="overflow-y-scroll h-96">
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-3 p-inner">
          {images.map((image) => (
            <ResponsiveImage
              path={image}
              onClick={() => {
                setPreview(image);
              }}
              className="cursor-pointer hover:scale-105 transition rounded-theme"
              width={150}
              height={100}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export function TenantPackageAddOnGalleryExamplesNumber({
  children,
  images,
  name,
  cost,
  value,
  disabled,
  ...template
}: Omit<TenantPackageAddOnTemplateProps, 'image'> & {
  name: string;
  images: [string, ...string[]];
  value?: string;
  disabled?: boolean;
  cost?: string | number;
}) {
  const [_name, count, setCount] = useZodFormFieldSingle(name);
  const checked = Number(count) > 0;
  const [show, setShow] = useState(false);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      image={images[0]}
      disabled={disabled}
      cost={cost ? `${format_money(cost)}/each` : undefined}
      enable={
        <InputCheckbox
          checked={checked}
          onChange={() => {
            if (checked) {
              setCount(null);
            } else {
              setCount('1');
            }
          }}
        />
      }
      onClick={() => setShow(true)}
    >
      {children}
      {checked ? (
        <FormHorizontal label="Quantity">
          <ZodFieldInput type="number" name={name} />
        </FormHorizontal>
      ) : template.persona === PersonaType.CUSTOMER ? (
        <div className="pt-2">
          <Link onClick={() => setShow(true)} icon={<IconVisible />}>
            View Examples
          </Link>
          <ZodFieldHidden name={name} value="0" />
        </div>
      ) : (
        <ZodFieldHidden name={name} value="0" />
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <GallerySingle name={name} images={images} />
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnGalleryExamplesCheckbox({
  children,
  images,
  name,
  cost,
  value,
  force = true,
  disabled,
  ...template
}: Omit<TenantPackageAddOnTemplateProps, 'image'> & {
  name: string;
  force?: boolean;
  images: [string, ...string[]];
  value?: string;
  disabled?: boolean;
  cost?: string | number;
}) {
  const [show, setShow] = useState(false);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      name={name}
      image={images[0]}
      disabled={disabled}
      cost={cost ? format_money(cost) : undefined}
      enable={<ZodFieldCheckbox id={name} name={name} value={value} force={force} disabled={disabled} />}
      onClick={() => setShow(true)}
    >
      {children}
      {template.persona === PersonaType.CUSTOMER && (
        <div className="pt-2">
          <Link onClick={() => setShow(true)} icon={<IconVisible />}>
            View Examples
          </Link>
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <GallerySingle name={name} images={images} />
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}

export interface TenantPackageGroup {
  name: string;
  images: string[];
}

export function TenantPackageAddOnNeighborhoods({
  children,
  groups,
  name,
  cost,
  value,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  groups: TenantPackageGroup[];
  cost?: string | number;
  value?: string;
}) {
  const [_name, selected, setSelected] = useZodFormFieldMultiple(name);
  const [show, setShow] = useState(false);
  const checked = selected.length > 0;

  return (
    <TenantPackageAddOnTemplate
      {...template}
      cost={cost ? format_money(cost) : undefined}
      enable={
        <InputCheckbox
          checked={checked}
          disabled={template.disabled}
          onChange={() => {
            if (checked) {
              setSelected([]);
            } else {
              setShow(true);
            }
          }}
        />
      }
    >
      {children}
      {checked && (
        <div className="pt-2 space-y-1">
          {selected.map((group, index) => (
            <div className="flex items-center space-x-3" key={group}>
              <div>
                <Button
                  icon={<IconDelete />}
                  onClick={(e) => {
                    e.preventDefault();
                    setSelected(selected.filter((s) => s !== group));
                  }}
                  variant="transparent"
                />
              </div>
              <div className="flex-grow">{group}</div>
              <ZodFieldHidden name={`${name}[${index}]`} value={group} />
            </div>
          ))}
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <NeighborhoodSelection name={name} groups={groups} />
      </Modal>
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnGallerySingleCheckbox({
  children,
  images,
  name,
  cost,
  value,
  ...template
}: Omit<TenantPackageAddOnTemplateProps, 'image'> & {
  name: string;
  images: [string, ...string[]];
  cost?: string | number;
  value?: string;
}) {
  const [_name, selected, setSelected] = useZodFormFieldSingle(name);
  const [show, setShow] = useState(false);
  const checked = Boolean(selected);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      image={selected ?? images[0]}
      cost={cost ? format_money(cost) : undefined}
      enable={
        <InputCheckbox
          checked={checked}
          disabled={template.disabled}
          onChange={() => {
            if (checked) {
              setSelected(null);
            } else {
              setShow(true);
            }
          }}
        />
      }
    >
      {children}
      {selected && (
        <div className="pt-2">
          <Button onClick={() => setShow(true)} variant="outline">
            Change Selection
          </Button>
        </div>
      )}
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <GallerySingle
          name={name}
          images={images}
          onSelect={(image) => {
            setSelected(image);
            setShow(false);
          }}
        />
      </Modal>
      <ZodFieldHidden name={name} value={selected ? value ?? selected : undefined} />
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnGeneric({
  cost,
  ...template
}: TenantPackageAddOnTemplateProps & {
  cost?: string | number;
}) {
  return <TenantPackageAddOnTemplate {...template} cost={cost ? format_money(cost) : undefined} />;
}

export function TenantPackageAddOnRepeat({
  children,
  name,
  cost,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  cost?: string | number;
}) {
  const [items, add, clear] = useZodFormFieldArray(name);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      name={name}
      cost={cost ? `${format_money(cost)}/each` : undefined}
      enable={
        <InputCheckbox
          id={name}
          checked={items.length > 0}
          onChange={() => {
            if (items.length === 0) {
              add({});
            } else {
              clear();
            }
          }}
        />
      }
    >
      <div className="space-y-2 pt-2">
        {items.map((item) => (
          <ZodNestedForm name={item.name} key={item.name}>
            <div className="flex items-center space-x-3">
              <div>
                <Button
                  icon={<IconDelete />}
                  onClick={(e) => {
                    e.preventDefault();
                    item.remove();
                  }}
                  variant="transparent"
                />
              </div>
              <div className="flex-grow">{children}</div>
            </div>
          </ZodNestedForm>
        ))}
        {items.length > 0 && (
          <div className="text-right">
            <Link icon={<IconAdd />} onClick={add}>
              Add Another
            </Link>
          </div>
        )}
      </div>
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnInput({
  name,
  cost,
  label,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  label: string;
  cost?: string | number;
}) {
  const [_name, value, setValue] = useZodFormFieldSingle(name);
  const [checked, setChecked] = useState(typeof value === 'string' && value.length > 0);

  return (
    <TenantPackageAddOnTemplate
      name={name}
      {...template}
      cost={cost ? format_money(cost) : undefined}
      enable={
        <InputCheckbox
          id={name}
          checked={checked}
          onChange={() => {
            if (checked) {
              setValue(null);
              setChecked(false);
            } else {
              setChecked(true);
            }
          }}
        />
      }
    >
      {checked ? (
        <FormHorizontal label={label}>
          <ZodFieldInput name={name} />
        </FormHorizontal>
      ) : (
        <ZodFieldHidden name={name} value={value} />
      )}
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageAddOnCheckbox({
  name,
  cost,
  value,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  cost?: string | number;
  value?: string;
}) {
  const id = `${name}-${value}`;

  return (
    <TenantPackageAddOnTemplate
      htmlFor={id}
      name={name}
      {...template}
      cost={cost ? format_money(cost) : undefined}
      enable={<ZodFieldCheckbox id={id} name={name} value={value} force={false} />}
    />
  );
}

export function TenantPackageAddOnRadioCards({
  name,
  options,
  cost,
  cols,
  ...template
}: TenantPackageAddOnTemplateProps & {
  name: string;
  options: InputRadioCardsOption[];
  cost?: string;
  cols?: number;
}) {
  const [_name, value, setValue] = useZodFormFieldSingle(name);
  const id = useId();

  const [show, setShow] = useState(value !== undefined);

  const handleCheckbox = useCallback((checked: boolean) => {
    setShow(checked);

    if (!checked) {
      setValue(null);
    }
  }, []);

  return (
    <TenantPackageAddOnTemplate
      {...template}
      htmlFor={`${name}-${id}`}
      cost={cost ? format_money(cost) : undefined}
      enable={<InputCheckbox id={`${name}-${id}`} checked={show} onChange={handleCheckbox} />}
    >
      {show && (
        <div className="pt-1">
          <ZodFieldRadioCards
            name={name}
            options={options}
            default={value ?? options[0].value}
            cols={cols}
          />
        </div>
      )}
    </TenantPackageAddOnTemplate>
  );
}

export function TenantPackageInclude(props: { image?: string; name: string; children: String }) {
  return (
    <li className="flex items-center space-x-3">
      {props.image ? (
        <div className="rounded-lg overflow-hidden border-theme-separator border flex-shrink-0">
          <ResponsiveImage path={props.image} width={100} height={67} />
        </div>
      ) : (
        <div className="w-6" />
      )}

      <span className="flex flex-col space-y-1">
        <span className="text-base font-bold flex items-center space-x-1">
          <span className="w-6 h-6 inline-block">
            <IconCheck />
          </span>{' '}
          <span>{props.name}</span>
        </span>
        <span className="text-sm text-gray-600">{props.children}</span>
      </span>
    </li>
  );
}

export function TenantPackageIncludes({ children }: { children?: React.ReactNode }) {
  return (
    <div className="space-y-5">
      <Heading title="What's included">The following products and services are included in this package:</Heading>

      <ul className="space-y-5">{children}</ul>
    </div>
  );
}
