import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'staging',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      style: z.union([
        z.literal('modern'),
        z.literal('scandinavian'),
        z.literal('contemporary'),
        z.literal('hamptons'),
        z.literal('traditional'),
        z.literal('farmhouse'),
        z.literal('urban'),
        z.literal('outdoor'),
      ]),
      quantity: z.string().pipe(z.coerce.number()).or(z.number()),
      renovations: z.string().pipe(z.coerce.number()).or(z.number()).optional(),
    }),
  },
  {
    name: 'Virtual Staging',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
  },
);
