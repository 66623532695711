import dedent from 'dedent';
import { FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { BreOrderSchema, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreAerialConfig from '~/tenants/bre/performable/aerial/BreAerialConfig';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreCinematicConfig from '~/tenants/bre/performable/cinematic/BreCinematicConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreSocialConfig from '~/tenants/bre/performable/social/BreSocialConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantPackageConfig } from '~/tenants/common/registry';

const BreCinematicsPackageConfig: TenantPackageConfig = {
  id: 'cinematics',
  name: 'Cinematics',
  performables: [
    BrePhotosConfig,
    BreFloorplanConfig,
    BreSocialConfig,
    BreCinematicConfig,
    BreAerialConfig,
    BreBoostConfig,
    BreWebsiteConfig,
  ],
  eligible: (context) => {
    const metadata = BreOrderSchema[FIRST_VERSION_TIMESTAMP].parse(context.metadata);

    return metadata.type !== BreOrderType.MARKETING;
  },
  description: dedent`
    Includes our ground photography, a 2D Floor Plan, a social media video, a \
    cinematic video, an aerial photo & video, a social media boost, and the \
    single property website page.
  `,
};

export default BreCinematicsPackageConfig;
