import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import { TenantPerformableConfiguration } from '~/tenants/common/performable';
import { BROCHURES_OPTIONS, MPI_BROCHURES_SHIPPING_RUSH, MPI_BROCHURES_SHIPPING_STANDARD } from './MpiBrochuresJob';
import { MpiOrderContext } from '../../model/MpiOrder';
import { MpiBrochureShipping, MpiBrochuresOption } from './MpiBrochuresConfig';

export default function MpiBrochuresForm(_props: TenantPerformableFormProps<MpiOrderContext>) {
  const [_optionName, option] = useZodFormFieldSingle<MpiBrochuresOption>('option');
  const [_quatityName, quatity] = useZodFormFieldSingle<MpiBrochuresOption>('quantity');

  return (
    <TenantPerformableConfiguration>
      <FormHorizontal name="option" label="Size">
        <ZodFieldSelect
          name="option"
          options={Object.entries(BROCHURES_OPTIONS).map(([id, pkg]) => ({
            value: id,
            label: `${pkg.pages} Pages (${pkg.width} x ${pkg.height})`,
          }))}
        />
      </FormHorizontal>

      {option && (
        <FormHorizontal name="quantity" label="Quantity">
          <ZodFieldSelect
            name="quantity"
            options={Object.entries(BROCHURES_OPTIONS[option].revenue).map(([id, _]) => ({
              value: id,
              label: id,
            }))}
          />
        </FormHorizontal>
      )}

      {quatity && (
        <FormHorizontal name="shipping" label="Shipping">
          <ZodFieldSelect
            name="shipping"
            options={[
              {
                label: `Standard ( + $${MPI_BROCHURES_SHIPPING_STANDARD})`,
                value: MpiBrochureShipping.STANDARD,
              },
              {
                label: `Rush ( + $${MPI_BROCHURES_SHIPPING_RUSH})`,
                value: MpiBrochureShipping.RUSH,
              },
            ]}
          />
        </FormHorizontal>
      )}
    </TenantPerformableConfiguration>
  );
}
