import Big from 'big.js';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreTwilightConfig from '~/tenants/bre/performable/twilight/BreTwilightConfig';
import { DeliverableType } from '~common/model/Deliverable';

const BaseRates = {
  COPYRIGHT: '50',
  PHOTOS_8: '395',
  PHOTOS_15: '475',
  PHOTOS_25: '575',
  SPLIT: 0.65,
};

export const BreTwilightRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    PHOTOS_8: '500',
  },
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BreTwilightJob extends BreJob<typeof BreTwilightConfig> {
  get performable() {
    return BreTwilightConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreTwilightConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: this.order.metadata.type === BreOrderType.COMMERCIAL ? 15 : 8,
    };
  }

  // TODO: Ask Paul for onsite times
  onsite(): number {
    return 0;
  }

  revenueLines(): TenantJobLine[] {
    const { COPYRIGHT, PHOTOS_8, PHOTOS_15, PHOTOS_25 } = BreTwilightRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.metadata.photos <= 8) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(PHOTOS_8).times(this.extraFee),
      });
    } else if (this.metadata.photos <= 15) {
      lines.push({
        id: 'twilight',
        description: '15 Twilight Photos',
        amount: new Big(PHOTOS_15).times(this.extraFee),
      });
    } else if (this.metadata.photos <= 25) {
      lines.push({
        id: 'twilight',
        description: '25 Twilight Photos',
        amount: new Big(PHOTOS_25).times(this.extraFee),
      });
    }

    if (this.metadata.copyright) {
      lines.push({
        id: 'copyright',
        description: 'Release w/ copyright',
        amount: new Big(COPYRIGHT).times(this.metadata.photos),
      });
    }

    return lines;
  }

  expenseLines(): TenantJobLine[] {
    const { PHOTOS_8, PHOTOS_15, PHOTOS_25, SPLIT } = BreTwilightRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.metadata.photos <= 8) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(PHOTOS_8).times(SPLIT),
      });
    } else if (this.metadata.photos <= 15) {
      lines.push({
        id: 'twilight',
        description: '15 Twilight Photos',
        amount: new Big(PHOTOS_15).times(SPLIT),
      });
    } else if (this.metadata.photos <= 25) {
      lines.push({
        id: 'twilight',
        description: '25 Twilight Photos',
        amount: new Big(PHOTOS_25).times(SPLIT),
      });
    }

    return lines;
  }
}
