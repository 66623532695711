import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { boostSchema } from '~/tenants/bre/performable/boost/BreBoostConfig';

export default createPerformableConfig(
  'website',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      type: z.union([z.literal('website'), z.literal('renewal')]),
      domain: coerceBoolean().optional(),
      boost: boostSchema.optional(),
    }),
  },
  {
    name: 'Website',
    images: [],
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
  },
);
