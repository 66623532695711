import Big from 'big.js';
import { DateTime } from 'luxon';
import { match } from 'ts-pattern';
import { TenantJobLine, addressToArea, addressToStreet } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreBoostJob from '~/tenants/bre/performable/boost/BreBoostJob';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantSubscriptionMetadata, TenantSubscriptionStatus, TenantSubscriptionType } from '~/tenants/common/TenantPersona';

const BaseRates = {
  DOMAIN: '25',
  RENEWAL: '20',
  WEBSITE: '50',
};

export const BreWebsiteRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BreWebsiteJob extends BreJob<typeof BreWebsiteConfig> {
  get performable() {
    return BreWebsiteConfig;
  }

  get configurable(): boolean {
    return true;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreWebsiteConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: 'website',
    };
  }

  subscription(): TenantSubscriptionMetadata {
    const { RENEWAL, WEBSITE } = BreWebsiteRate[this.order.metadata.type];

    return {
      type: TenantSubscriptionType.MICROSITE,
      name: this.order.address
        ? `${addressToStreet(this.order.address)}, ${addressToArea(this.order.address)}`
        : 'Single Property Website',
      status: TenantSubscriptionStatus.ACTIVE,
      created: DateTime.now().toJSDate().toISOString(),
      order_id: this.order.id,
      job_id: this.id,
      months: 12,
      cost: match(this.metadata.type)
        .with('website', () => Number(WEBSITE))
        .with('renewal', () => Number(RENEWAL))
        .exhaustive(),
      expires: DateTime.now().plus({ month: 12 }).toJSDate().toISOString(),
      renew: false,
      payments: {},
      domain: "https://example.com",
    };
  }

  revenueLines(): TenantJobLine[] {
    const { DOMAIN, RENEWAL, WEBSITE } = BreWebsiteRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.metadata.type === 'website') {
      lines.push({
        id: 'website_type',
        description: 'Single Property Website',
        amount: new Big(WEBSITE),
        discountable: true,
      });

      if (this.matchSocialPackage) {
        lines.push({
          id: 'website_package',
          description: 'Social Media Package Website Discount',
          amount: new Big(WEBSITE).times(-0.1),
          discount: true,
        })
      }
    } else if (this.metadata.type === 'renewal') {
      lines.push({
        id: 'renewal',
        description: 'Subscription Renewal',
        amount: new Big(RENEWAL),
      });
    }

    if (this.metadata.domain) {
      lines.push({
        id: 'domain',
        description: 'Custom Domain',
        amount: new Big(DOMAIN),
      });
    }

    if (this.metadata.boost) {
      const boostJob = new BreBoostJob(this.order, undefined, {
        version: this.metadata.version,
        ...this.metadata.boost,
      });

      lines.push(...boostJob.revenueLines());
    }

    return lines;
  }
}
