import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { DeliverableType } from '~common/model/Deliverable';

const BaseRates = {
  COPYRIGHT: '50',
  POI: '25',
  RUSH: '75',
  TWILIGHT: '300',
};

export const BrePhotoRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: {
    ...BaseRates,
    TWILIGHT: '400',
  },
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BrePhotosJob extends BreJob<typeof BrePhotosConfig> {
  get performable() {
    return BrePhotosConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  get photosRevenue(): Big {
    const { photos } = this.metadata;

    return this.order.metadata.type === BreOrderType.COMMERCIAL
      ? new Big('60').times(photos).add(100)
      : new Big('7').times(photos).add(95);
  }

  get extrasRevenue(): Big {
    if (this.metadata.extra) {
      const total = this.metadata.photos + this.metadata.extra;
      const price = this.order.metadata.type === BreOrderType.COMMERCIAL
        ? new Big('60').times(total).add(100)
        : new Big('7').times(total).add(95);

      return price.minus(this.photosRevenue).times(this.extraFee);
    }

    return new Big('0');
  }

  defaultValue(): ZodVersionedMetadata<(typeof BrePhotosConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      photos: 15,
    };
  }

  // TODO: Waiting for times from Chris
  onsite(): number {
    const { photos } = this.metadata;

    if (photos <= 25) {
      return 45;
    }

    if (photos <= 35) {
      return 60;
    }

    return 75;
  }

  revenueLines(): TenantOrderLine[] {
    const { COPYRIGHT, POI, TWILIGHT, RUSH } = BrePhotoRate[this.order.metadata.type];
    const lines: TenantOrderLine[] = [];

    if (this.metadata.photos > 0) {
      lines.push({
        id: 'photos',
        description: `${this.metadata.photos} Daytime Photos`,
        amount: this.photosRevenue.times(this.extraFee),
        discountable: true,
      });

      if (this.matchSocialPackage) {
        lines.push({
          id: 'photo_package',
          description: 'Social Media Package Photo Discount',
          amount: this.photosRevenue.times(this.extraFee).times(-0.1),
          discount: true,
        });
      }
    }

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(TWILIGHT).times(this.extraFee),
      });
    }

    if (this.metadata.copyright) {
      const twilight = this.metadata.twilight ? 8 : 0;
      const count = this.metadata.photos + twilight;

      lines.push({
        id: 'copyright',
        description: `${count} Photos Copyright`,
        amount: new Big(COPYRIGHT).times(count),
      });
    }

    if (this.order.metadata.rush) {
      lines.push({
        id: 'rush',
        description: 'Rush Fee',
        amount: new Big(RUSH),
      })
    }

    if (this.metadata.poi && this.metadata.poi.length > 0) {
      lines.push({
        id: 'poi',
        description: 'Points of Interest',
        amount: new Big(POI).times(this.metadata.poi.length),
      });
    }

    return lines;
  }

  expenseLines(): TenantOrderLine[] {
    const { POI, TWILIGHT } = BrePhotoRate[this.order.metadata.type];
    const lines: TenantOrderLine[] = [];

    if (this.metadata.photos > 0) {
      lines.push({
        id: 'photos',
        description: `${this.metadata.photos} Daytime Photos`,
        amount: this.photosRevenue.times(0.65).times(this.matchSocialPackage ? 0.9 : 1),
      })
    }

    if (this.metadata.twilight) {
      lines.push({
        id: 'twilight',
        description: '8 Twilight Photos',
        amount: new Big(TWILIGHT).times(0.65),
      })
    }

    if (this.metadata.poi && this.metadata.poi.length > 0) {
      lines.push({
        id: 'poi',
        description: 'Points of Interest',
        amount: new Big(POI).times(this.metadata.poi.length).times(0.65),
      });
    }

    return lines;
  }
}
