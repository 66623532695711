import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import ZodFieldInput from '~/components/zod/ZodFieldInput';

export default function BreDuskForm() {
  return (
    <FormGroup>
      <FormHorizontal name="quantity" label="Quantity">
        <ZodFieldInput name="quantity" type="number" />
      </FormHorizontal>
    </FormGroup>
  );
}
