import { TenantPackageAddOnGalleryExamplesCheckbox } from '~/tenants/common/package';
import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import range from 'lodash.range';
import {
  MPI_PHOTO_TWILIGHT_NATURAL_SUMMER,
  MPI_PHOTO_TWILIGHT_NATURAL_WINTER,
  MPI_PHOTO_TWILIGHT_VIRTUAL,
} from './MpiPhotosJob';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { FormHorizontal } from '~/components/form/layout';
import { format_money } from '~/components/Money';
import Big from 'big.js';
import { TimeZone } from '~/lib/enum';
import { datetime } from '~/lib/datettime';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { MpiOrderContext } from '../../model/MpiOrder';

export enum MpiTwilightPhotoType {
  NATURAL = 'natural',
  VIRTUAL = 'virtual',
}

function twilightPhotos(count: number, type: MpiTwilightPhotoType, created: TDateISO, timezone: TimeZone) {
  switch (type) {
    case MpiTwilightPhotoType.NATURAL:
      return {
        label: `${count} Photos: ${format_money(
          new Big(
            datetime(created, timezone).isInDST ? MPI_PHOTO_TWILIGHT_NATURAL_SUMMER : MPI_PHOTO_TWILIGHT_NATURAL_WINTER,
          ).toFixed(2),
        )}`,
        value: count.toString(),
      };
    case MpiTwilightPhotoType.VIRTUAL:
      return {
        label: `${count} Photos: ${format_money(new Big(MPI_PHOTO_TWILIGHT_VIRTUAL).times(count).toFixed(2))}`,
        value: count.toString(),
      };
    default:
      return {
        label: 'Nothing to select',
        value: count.toString(),
      };
  }
}

// TODO: Joel: Need to update these assets for MPI
const MPI_PHOTOS_TWILIGHT: [string, ...string[]] = [
  'tenant/twt/twilight/authentic/2.jpg',
  ...range(1, 13)
    .map((i) => `tenant/twt/twilight/authentic/${i}.jpg`)
    .filter((i) => !i.includes('/2.jpg')),
];

export default function MpiPhotosTwilight({ persona, context }: TenantPerformableFormProps<MpiOrderContext>) {
  const twilightType = useZodFormFieldSingleValue<MpiTwilightPhotoType>('twilight_type');
  const numberOfPhotos = useZodFormFieldSingleValue('twilight_photos');

  const cost = numberOfPhotos
    ? (() => {
        switch (twilightType) {
          case MpiTwilightPhotoType.NATURAL:
            // For now we can't dynamically access timezone
            return datetime(context.created, TimeZone.US_CENTRAL).isInDST
              ? MPI_PHOTO_TWILIGHT_NATURAL_SUMMER
              : MPI_PHOTO_TWILIGHT_NATURAL_WINTER;
          case MpiTwilightPhotoType.VIRTUAL:
            return MPI_PHOTO_TWILIGHT_VIRTUAL * parseInt(numberOfPhotos);
          default:
            return;
        }
      })()
    : undefined;

  return (
    <>
      <TenantPackageAddOnGalleryExamplesCheckbox
        name="twilight"
        persona={persona}
        images={MPI_PHOTOS_TWILIGHT}
        title="Twilight Shoot"
        cost={cost}
        description="Add this service and we will combine your day shoot with Twilight exteriors. We will arrive an hour or so prior to sunset to photograph day exteriors as well as all interiors and then head out to capture twilight exteriors."
      >
        <FormHorizontal name="twilight_type" label="Type" simple>
          <ZodFieldSelect
            options={[
              { label: 'Natural twilight', value: MpiTwilightPhotoType.NATURAL },
              { label: 'Virtual twilight', value: MpiTwilightPhotoType.VIRTUAL },
            ]}
            name="twilight_type"
          />
        </FormHorizontal>
        {twilightType && (
          <ZodFieldSelect
            options={[
              twilightPhotos(5, twilightType, context.created, TimeZone.US_CENTRAL),
              twilightPhotos(10, twilightType, context.created, TimeZone.US_CENTRAL),
              twilightPhotos(15, twilightType, context.created, TimeZone.US_CENTRAL),
            ]}
            name="twilight_photos"
          />
        )}
      </TenantPackageAddOnGalleryExamplesCheckbox>
    </>
  );
}
