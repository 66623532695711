import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnGalleryExamplesCheckbox } from '~/tenants/common/package';
import { MPI_VIDEO_AGENT_ON_CAMERA } from './MpiVideoJob';

export default function MpiVideoAddOns() {
  return (
    <>
      <TenantPackageAddOnGalleryExamplesCheckbox
        name="agent_on_camera"
        title="Agent On Camera"
        // TODO: Joel: Add proper images
        images={['tenant/twt/residential/1.jpg']}
        persona={PersonaType.CUSTOMER}
        description="Have one of the agens speak on camera and walk through your listing. Customized per project"
        cost={MPI_VIDEO_AGENT_ON_CAMERA}
      />
    </>
  );
}
