import { useZodFormFieldSingleValue } from '~/components/zod/ZodForm';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreAerialRate } from '~/tenants/bre/performable/aerial/BreAerialJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';

interface BreAerialFPVProps {
  persona: PersonaType;
  orderType: BreOrderType;
}

export default function BreAerialFPVOddOn({ orderType, persona }: BreAerialFPVProps) {
  const { FPV_BOTH, FPV_OUTSIDE } = BreAerialRate[orderType];
  const fpv = useZodFormFieldSingleValue('fpv');

  return (
    <>
      <TenantPackageAddOnCheckbox
        name="fpv"
        value="outside"
        title="FPV Drone Video: Outside"
        description="Add an Outside only FPV (First-Person View) Drone Video"
        image="" // ? what image should we use here
        persona={persona}
        cost={FPV_OUTSIDE}
        disabled={fpv === 'both'}
      />
      <TenantPackageAddOnCheckbox
        name="fpv"
        value="both"
        title="FPV Drone Video: Inside + Outside"
        description="Add an Inside and Outside FPV (First-Person View) Drone Video"
        image="" // ? what image should we use here
        persona={persona}
        cost={FPV_BOTH}
        disabled={fpv === 'outside'}
      />
    </>
  );
}
