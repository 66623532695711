import z from 'zod';
import { InputRadioCardsOption } from '~/components/input/InputRadioCards';
import { capitalize } from '~/lib/format';

export type VoiceType = z.infer<typeof voiceSchema>;

export const voiceSchema = z.union([z.literal('male'), z.literal('female')]);

export const VOICE_OPTS = voiceSchema.options.map<InputRadioCardsOption>(({ value }) => ({
  title: `${capitalize(value)} Voice`,
  description: `We narrate with a ${value} voice`,
  value,
}));
