import FormGroup from '~/components/form/FormGroup';
import { BreOrderContext } from '~/tenants/bre/model/BreOrderContext';
import { BreBoostAddOn } from '~/tenants/bre/performable/BreBoostAddOn';
import { VOICE_OPTS } from '~/tenants/bre/performable/common';
import { BreSocialRate } from '~/tenants/bre/performable/social/BreSocialJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox, TenantPackageAddOnRadioCards } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

export default function BreSocialForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const {
    AERIAL_PHOTOS,
    AERIAL_VIDEO,
    INTRO,
    NARRATION,
    TWILIGHT,
    VOICEOVER,
  } = BreSocialRate[context.metadata.type];

  return (
    <FormGroup>
      <TenantPerformableAddOns persona={persona}>
        <TenantPackageAddOnCheckbox
          name="aerial_photos"
          title="Aerial Photos"
          description="Add 5 aerial photos"
          image="" // ? what should we use here
          persona={persona}
          cost={AERIAL_PHOTOS}
        />
        <TenantPackageAddOnCheckbox
          name="aerial_video"
          title="Aerial Video"
          description="Add aerial video"
          image="" // ? what should we use here
          persona={persona}
          cost={AERIAL_VIDEO}
        />
        <TenantPackageAddOnRadioCards
          name="intro"
          title="Agent Intro"
          description="Add agent intro"
          image="" // ? what should we use here
          persona={persona}
          cost={INTRO}
          options={VOICE_OPTS}
          cols={2}
        />
        <TenantPackageAddOnRadioCards
          name="narration"
          title="Agent Narration"
          description="Add agent narration"
          image="" // ? what should we use here
          persona={persona}
          cost={NARRATION}
          options={VOICE_OPTS}
          cols={2}
        />
        <TenantPackageAddOnRadioCards
          name="voiceover"
          title="Kwasi Voice Over"
          description="Add Kwasi voice over"
          image="" // ? what should we use here
          persona={persona}
          cost={VOICEOVER}
          options={VOICE_OPTS}
          cols={2}
        />
        <TenantPackageAddOnCheckbox
          name="twilight"
          title="Twilight Upgrade"
          description="Add same-day twilight"
          image="" // ? what should we use here
          persona={persona}
          cost={TWILIGHT}
        />
        <BreBoostAddOn name="boost" persona={persona} />
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

/*

Quick overview of the main areas of the home. Rendered in a Vertical - Up to a 1-minute - Clip for quick upload to any social media platform and reel. Fun, Quick, and Effective.

A quick agent intro (10-15 seconds) can be added on-site.

Post Completion, narration can be overlaid with or without on-site agent intro.

You can choose to boost these clips on social media if you have a Social Media Boost selected under the Single Property Website Page.

----

Quick overview of the main areas of the home. Rendered in a Vertical - Up to a 1-minute - Clip for quick upload to any social media platform and reel.
 */

/*
Voice Narration read by Kwasi

BREM Provider Kwasi will dictate the script of the property narration.
 */
