import z from 'zod';
import dedent from 'dedent';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP, zodTDateISODate } from '~/lib/zod';

export type Reach = z.infer<typeof reachSchema>;

export type Boost = z.infer<typeof boostSchema>;

const reachSchema = z.union([
  z.literal('3,000'),
  z.literal('5,000'),
  z.literal('7,000'),
  z.literal('9,250'),
  z.literal('11,500'),
  z.literal('14,000'),
  z.literal('16,500'),
  z.literal('20,000'),
  z.literal('24,000'),
]);

export const REACH_VALUES = reachSchema.options.map((o) => o.value);

export const boostSchema = z.object({
  reach: reachSchema,
  start_at: zodTDateISODate(),
});

export default createPerformableConfig(
  'boost',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
    })
    .merge(boostSchema),
  },
  {
    name: 'Social Media Boosting',
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: dedent`
      Our Facebook / Instagram boosting helps market your listing in the city \
      of the property.

      It provides immediate exposure for “Just Listed” or “Sold” properties and \
      provides an interactive buying experience where potential customers can \
      view photos, tours and descriptions of the property.

      By using our social media boosting, you can easily share the listing with \
      family and friends. You could even go viral! The more you spend the wider \
      the reach.
    `,
    images: [],
  },
);
