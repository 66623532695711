import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldDate from '~/components/zod/ZodFieldDate';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { REACH_VALUES } from '~/tenants/bre/performable/boost/BreBoostConfig';

const REACH_OPTS: InputSelectOption[] = REACH_VALUES.map((reach) => ({
  label: `Up to ${reach}`,
  value: reach,
}));

export function BreBoostFields() {
  return (
    <>
      <FormHorizontal name="reach" label="Potential Reach">
        <ZodFieldSelect name="reach" options={REACH_OPTS} />
      </FormHorizontal>
      <FormHorizontal name="start_at" label="Start Date">
        <ZodFieldDate name="start_at" />
      </FormHorizontal>
    </>
  )
}