import { TenantProvider } from '../../common/TenantPersona';
import { MpiOrderContext } from '~/tenants/mpi/model/MpiOrder';
import MpiPhotosConfig from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiAerialConfig from '../performable/aerial/MpiAerialConfig';
import MpiBrochuresConfig from '../performable/brochures/MpiBrochuresConfig';
import MpiFloorplanConfig from '../performable/floorplan/MpiFloorplanConfig';
import MpiIguideConfig from '../performable/iguide/MpiIguideConfig';
import MpiMatterportConfig from '../performable/matterport/MpiMatterportConfig';
import MpiVirtualStagingConfig from '../performable/staging/MpiVirtualStagingConfig';
import MpiVideoConfig from '../performable/video/MpiVideoConfig';

export enum MpiProviderId {
  HOULTON_MAHANEY = 1,
  DAN_WEINEL = 2,
  ALEX_GREGORY = 3,
  SOPHIA_BEDNARIK = 4,
  TIFFANY_LANG = 5,
  STEPHEN_POSKO = 6,
  BRAYLAN_DODSON = 7,
  //MITCHELL_GOLDSTEIN = 8,
  CHAY_DEAVER = 9,
  BRIAN_WOODS = 10,
  // TJ_DARPINO = 11,
  MIKE_DAVIS = 12,
  //NICE_PLACE = 13,
  NICK_KIPKE = 14,
  NIK_TYLER = 15,
  //DANIELLE_BOLLING = 16,
  //AARON_JACKSON = 17,
  //MARISA_ARNOLD = 18
}

export interface MpiProviderStaticData {}

export default class MpiProvider extends TenantProvider<MpiProviderStaticData> {
  canPerform(context: MpiOrderContext, job: MpiOrderContext['jobs'][0]): true | string {
    return true;
  }

  get performableIds(): string[] {
    return [
      MpiPhotosConfig.id,
      MpiAerialConfig.id,
      MpiBrochuresConfig.id,
      MpiFloorplanConfig.id,
      MpiIguideConfig.id,
      MpiMatterportConfig.id,
      MpiVirtualStagingConfig.id,
      MpiVideoConfig.id,
    ];
  }
}
