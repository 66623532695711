import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import z from 'zod';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

// TODO: Paul to get prices for Front, Outside, and Rental 
export default createPerformableConfig(
  'photos',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      photos: z.string().pipe(z.coerce.number()).or(z.number()),
      extra: z.string().pipe(z.coerce.number()).or(z.number()).optional(),
      notes: z.string().optional(),
      copyright: coerceBoolean().optional(),
      twilight: coerceBoolean().optional(),
      poi: z.array(z.object({ name: z.string(), location: z.string() })).optional(),
    }),
  },
  {
    name: 'Photography',
    images: [],
    short: '',
    tenant: TenantId.BEYOND_RE_MARKETING,
  },
);
