import FormGroup from '~/components/form/FormGroup';
import { FormHorizontal } from '~/components/form/layout';
import { InputSelectOption } from '~/components/input/InputSelect';
import ZodFieldSelect from '~/components/zod/ZodFieldSelect';
import { BreOrderContext, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import { BreMatterportRate } from '~/tenants/bre/performable/matterport/BreMatterportJob';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import { TenantPackageAddOnCheckbox } from '~/tenants/common/package';
import { TenantPerformableAddOns } from '~/tenants/common/performable';

const PLAN_OPTS: InputSelectOption[] = [
  { label: 'Basic', value: 'basic' },
  { label: 'Advanced', value: 'advanced' },
];

export default function BreMatterportForm({ context, persona }: TenantPerformableFormProps<BreOrderContext>) {
  const { OUTDOOR, OUTDOOR_XL } = BreMatterportRate[context.metadata.type];
  const isCommercial = context.metadata.type === BreOrderType.COMMERCIAL;

  if (context.metadata.type === BreOrderType.MARKETING) {
    return null;
  }

  return (
    <FormGroup>
      {isCommercial && (
        <FormHorizontal name="plan" label="Plan">
          <ZodFieldSelect name="plan" options={PLAN_OPTS} />
        </FormHorizontal>
      )}

      <TenantPerformableAddOns persona={persona}>
        {isCommercial && (
          <TenantPackageAddOnCheckbox
            name="outdoor_commercial"
            title="Exterios"
            description="Add exterior scans"
            image="" // ? what should we use here
            persona={persona}
            cost={context.metadata.sqft < 8000 ? OUTDOOR : OUTDOOR_XL}
          />
        )}

        {!isCommercial && (
          <>
            <TenantPackageAddOnCheckbox
              name="outdoor_front"
              title="Exterios: Frontyard"
              description="Add an exterior frontyard scan"
              image="" // ? what should we use here
              persona={persona}
              cost={context.metadata.sqft < 8000 ? OUTDOOR : OUTDOOR_XL}
            />
            <TenantPackageAddOnCheckbox
              name="outdoor_back"
              title="Exterios: Backyard"
              description="Add an exterior backyardyard scan"
              image="" // ? what should we use here
              persona={persona}
              cost={context.metadata.sqft < 8000 ? OUTDOOR : OUTDOOR_XL}
            />
          </>
        )}
      </TenantPerformableAddOns>
    </FormGroup>
  );
}

/*
https://my.matterport.com/show/?m=RecLDuB1NTh

Our 3D Matterport Showcase is an online experience that lets home buyers 3-dimensionally move through a property and view it from any angle. Our unique ‘Dollhouse view’ provides you an outside looking in (no exterior walls or roof) providing an incredible visual experience.

We can also scan the outside areas as well for an additional price
 */
/*
If the appointment is booked for an address with multiple buildings, additional charges may apply.
 */

/*
Property to be photographed or filmed will be "Photo Ready" upon Photographers arrival, otherwise there will be a Same Day cancellation or delay fee.

Same-Day Cancellation = $125 (Less than 24 Hour Notice) or $175 (Less Than 3 Hour Notice).
Delay Fee = $50.00 per every 15 minutes up to 30 minutes
 */
