import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import { DeliverableType } from '~common/model/Deliverable';
import MpiFloorplanConfig from './MpiFloorplanConfig';
import MpiJob from '../../model/MpiJob';

const MPI_FLOOR_PLAN_MIN_SQFT = 1000;
const MPI_FLOOR_PLAN_MIN_AMOUNT = 100;
const MPI_FLOOR_PLAN_SQFT_MULTIPLIER = 0.025;

export default class MpiFloorplanJob extends MpiJob<typeof MpiFloorplanConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiFloorplanConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite() {
    return 30;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.FLOORPLAN;
  }

  get submission(): boolean {
    return true;
  }

  get performable() {
    return MpiFloorplanConfig;
  }

  get configurable() {
    return true;
  }

  info(): DisplayData[] {
    const info = super.info();

    return info;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      amount: new Big(MPI_FLOOR_PLAN_MIN_AMOUNT)
        .plus(
          new Big(
            Math.ceil((Math.max(this.order.metadata.sqft, MPI_FLOOR_PLAN_MIN_SQFT) - MPI_FLOOR_PLAN_MIN_SQFT) / 1000),
          )
            .times(1000)
            .times(MPI_FLOOR_PLAN_SQFT_MULTIPLIER),
        )
        .plus(
          Math.ceil((Math.max(this.order.metadata.sqft, MPI_FLOOR_PLAN_MIN_SQFT) - MPI_FLOOR_PLAN_MIN_SQFT) / 1000),
        ),
      description: `2D Floorplan`,
      id: 'floorplan',
    });

    return lines;
  }
}
