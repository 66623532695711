import { DisplayData, TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiJob from '../../model/MpiJob';
import MpiMatterportConfig from './MpiMatterportConfig';

export default class MpiMatterportJob extends MpiJob<typeof MpiMatterportConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiMatterportConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
    };
  }

  onsite() {
    return 30;
  }

  get performable() {
    return MpiMatterportConfig;
  }

  get configurable() {
    return true;
  }

  info(): DisplayData[] {
    const info = super.info();

    return info;
  }

  expenseLines(): TenantOrderLine[] {
    const lines = super.expenseLines();

    return lines;
  }

  revenueLines(): TenantOrderLine[] {
    const lines = super.revenueLines();

    lines.push({
      amount: new Big('0.03714285714').times(this.order.metadata.sqft).plus(200),
      description: `3D Matterport`,
      id: 'matterport',
    });

    return lines;
  }
}
