import { format_money } from '~/components/Money';
import ZodFieldCheckbox from '~/components/zod/ZodFieldCheckbox';

interface BreAerialVideoProps {
  cost?: string;
}

export default function BreAerialVideo({ cost }: BreAerialVideoProps) {
  return (
    <div className="flex flex-col gap-2">
      <div className='flex justify-between'>
        <label className='flex gap-2 font-medium cursor-pointer' htmlFor="aerial_video">
          <ZodFieldCheckbox id="aerial_video" name="video" />
          Aerial Video
        </label>
        {cost && (
          <span className="font-bold whitespace-nowraps">
            {format_money(cost)}
          </span>
        )}
      </div>
      <span className="text-gray-600 text-sm">
        Add aerial video clip
      </span>
    </div>
  );
}
