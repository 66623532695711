import { FormHorizontal } from '~/components/form/layout';
import { TenantPerformableFormProps } from '~/tenants/common/form';
import ZodFieldInput from '~/components/zod/ZodFieldInput';
import { TenantPerformableConfiguration } from '~/tenants/common/performable';
import { MpiOrderContext } from '../../model/MpiOrder';

export default function MpiVirtualStagingForm(_props: TenantPerformableFormProps<MpiOrderContext>) {
  return (
    <TenantPerformableConfiguration>
      <FormHorizontal name="photos" label="Photos">
        <ZodFieldInput name="photos" type="number" />
      </FormHorizontal>
    </TenantPerformableConfiguration>
  );
}
