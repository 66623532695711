import z from 'zod';
import { createPerformableConfig, TenantId } from '~/tenants/common/registry';
import { coerceBoolean, coerceLiteralNumberRequired, FIRST_VERSION_TIMESTAMP } from '~/lib/zod';

export default createPerformableConfig(
  'matterport',
  {
    [FIRST_VERSION_TIMESTAMP]: z.object({
      version: coerceLiteralNumberRequired(FIRST_VERSION_TIMESTAMP),
      outdoor_back: coerceBoolean().optional(),
      outdoor_front: coerceBoolean().optional(),
      outdoor_commercial: coerceBoolean().optional(),
      plan: z.union([z.literal('basic'), z.literal('advanced')]).optional(),
    }),
  },
  {
    name: '3D Matterport',
    tenant: TenantId.BEYOND_RE_MARKETING,
    short: '',
    images: [],
  },
);
