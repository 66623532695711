import { TenantOrderLine } from '~/lib/model';
import Big from 'big.js';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import MpiPhotosConfig, { MpiPhotosType } from '~/tenants/mpi/performable/photos/MpiPhotosConfig';
import MpiJob from '~/tenants/mpi/model/MpiJob';
import { PersonaType } from '~/tenants/common/TenantPersona';
import { DeliverableType } from '~common/model/Deliverable';
import { MpiOrderType } from '../../model/MpiOrder';
import { MpiTwilightPhotoType } from './MpiPhotosTwilight';
import { datetime } from '~/lib/datettime';
import { TimeZone } from '~/lib/enum';

export const MPI_PHOTO_EXTRA = 5;
export const MPI_PHOTO_COMMUNITY = 90;
export const MPI_PHOTO_TWILIGHT_NATURAL_SUMMER = 175;
export const MPI_PHOTO_TWILIGHT_NATURAL_WINTER = 285;
export const MPI_PHOTO_TWILIGHT_VIRTUAL = 25;

export default class MpiPhotosJob extends MpiJob<typeof MpiPhotosConfig> {
  defaultValue(): ZodVersionedMetadata<(typeof MpiPhotosConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      type: MpiPhotosType.STANDARD,
      twilight: false,
      twilight_type: MpiTwilightPhotoType.NATURAL,
    };
  }

  get deliverable() {
    return DeliverableType.PHOTO;
  }

  get media() {
    return { persona: PersonaType.PROVIDER };
  }

  get configurable(): boolean {
    return true;
  }

  static extraPhotos(amount: number) {
    return new Big(MPI_PHOTO_EXTRA).times(amount);
  }

  onsite(): number {
    if (this.metadata.twilight) {
      return 30;
    }

    // TODO: Joel: Need to confirm onsite times for this job
    return 50;
  }

  revenueLines(): TenantOrderLine[] {
    const lines: TenantOrderLine[] = [];

    lines.push({
      amount: (() => {
        switch (this.order.metadata.type) {
          case MpiOrderType.RESIDENTIAL:
            return new Big(240);
          case MpiOrderType.RENTAL:
            return new Big(165);
        }
      })(),
      description: 'Photos',
      id: 'photos',
    });

    if (this.metadata.twilight && this.metadata.twilight_type && this.metadata.twilight_photos) {
      lines.push({
        amount: (() => {
          switch (this.metadata.twilight_type) {
            case MpiTwilightPhotoType.NATURAL:
              // For now we can't dynamically access timezone
              return datetime(this.order.created, TimeZone.US_CENTRAL).isInDST
                ? new Big(MPI_PHOTO_TWILIGHT_NATURAL_SUMMER)
                : new Big(MPI_PHOTO_TWILIGHT_NATURAL_WINTER);
            case MpiTwilightPhotoType.VIRTUAL:
              return new Big(MPI_PHOTO_TWILIGHT_VIRTUAL).times(this.metadata.twilight_photos);
          }
        })(),
        discountable: false,
        description: 'Twilight Photos',
        id: 'twilight',
      });
    }

    if (this.metadata.community) {
      lines.push({
        amount: new Big(MPI_PHOTO_COMMUNITY),
        description: 'Community Photos',
        id: 'community',
      });
    }

    return lines;
  }

  get performable() {
    return MpiPhotosConfig;
  }
}
