import Big from 'big.js';
import { match } from 'ts-pattern';
import { TenantJobLine } from '~/lib/model';
import { FIRST_VERSION_TIMESTAMP, ZodVersionedMetadata } from '~/lib/zod';
import BreJob from '~/tenants/bre/model/BreJob';
import { BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreStagingConfig from '~/tenants/bre/performable/staging/BreStagingConfig';
import { DeliverableType } from '~common/model/Deliverable';

const BaseRates = {
  RENOVATION: '15',
  TIER_1: '50',
  TIER_2: '45',
  TIER_3: '40',
  TIER_4: '35',
};

export const BreStagingRate: Record<BreOrderType, typeof BaseRates> = {
  [BreOrderType.COMMERCIAL]: BaseRates,
  [BreOrderType.MARKETING]: BaseRates,
  [BreOrderType.RENTAL]: BaseRates,
  [BreOrderType.RESIDENTIAL]: BaseRates,
};

export default class BreStagingJob extends BreJob<typeof BreStagingConfig> {
  get performable() {
    return BreStagingConfig;
  }

  get configurable(): boolean {
    return true;
  }

  get deliverable(): DeliverableType {
    return DeliverableType.PHOTO;
  }

  defaultValue(): ZodVersionedMetadata<(typeof BreStagingConfig)['schema']> {
    return {
      version: FIRST_VERSION_TIMESTAMP,
      style: 'modern',
      quantity: 1,
    };
  }

  // TODO: Waiting onsite times from Chris
  onsite(): number {
    return 0;
  }

  revenueLines(): TenantJobLine[] {
    const { RENOVATION, TIER_1, TIER_2, TIER_3, TIER_4 } = BreStagingRate[this.order.metadata.type];
    const lines: TenantJobLine[] = [];

    if (this.metadata.quantity > 0) {
      const tier = match(this.metadata.quantity)
        .when((q) => q <= 3, () => TIER_1)
        .when((q) => q <= 6, () => TIER_2)
        .when((q) => q <= 9, () => TIER_3)
        .otherwise(() => TIER_4)

      lines.push({
        id: 'virtual_staging',
        description: 'Virtual Staging',
        amount: new Big(tier).times(this.metadata.quantity),
      });
    }

    if (this.metadata.renovations) {
      lines.push({
        id: 'virtual_renovation',
        description: 'Virtual Renovation',
        amount: new Big(RENOVATION).times(this.metadata.renovations),
      });
    }

    return lines;
  }
}
