import dedent from 'dedent';
import { FIRST_VERSION_TIMESTAMP } from '~/lib/zod';
import { BreOrderSchema, BreOrderType } from '~/tenants/bre/model/BreOrderContext';
import BreBoostConfig from '~/tenants/bre/performable/boost/BreBoostConfig';
import BreFloorplanConfig from '~/tenants/bre/performable/floorplan/BreFloorplanConfig';
import BrePhotosConfig from '~/tenants/bre/performable/photos/BrePhotosConfig';
import BreWebsiteConfig from '~/tenants/bre/performable/website/BreWebsiteConfig';
import { TenantPackageConfig } from '~/tenants/common/registry';

const BrePhotoPackageConfig: TenantPackageConfig = {
  id: 'photo',
  name: 'Photography',
  performables: [
    BrePhotosConfig,
    BreFloorplanConfig,
    BreWebsiteConfig,
    BreBoostConfig,
  ],
  eligible: (context) => {
    const metadata = BreOrderSchema[FIRST_VERSION_TIMESTAMP].parse(context.metadata);

    return metadata.type !== BreOrderType.MARKETING;
  },
  description: dedent`
    Includes our ground photography, a 2D Floor Plan, a social media boost, and \
    the single property website page.
  `,
};

export default BrePhotoPackageConfig;
